// @ts-nocheck
// EmailCard.jsx

import React from "react";
import styled from "styled-components";
import { Clock, Trash2 } from "lucide-react";
import Button from "./Button";
import Textarea from "./Textarea";

const EmailCardContainer = styled.div`
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  margin-bottom: 16px;

  ${(props) =>
    props.editing &&
    `
    border-color: #3b82f6;
  `}
`;

const EmailCardContent = styled.div`
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const EmailHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
  }
`;

const EmailDay = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  color: #6b7280;
`;

const EmailInput = styled.input`
  width: 320px;
  height: 36px;
  padding: 8px;
  border: 1px solid #d1d5db;
  border-radius: 4px;

  &:focus {
    outline: none;
    border-color: #3b82f6;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 24px;
`;

const FormLabel = styled.label`
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const EmailCard = ({
  sequence,
  editingSequence,
  setEditingSequence,
  handleSequenceChange,
  handleDeleteSequence,
}) => {
  const isEditing = editingSequence === sequence.clientId;
  return (
    <EmailCardContainer editing={isEditing}>
      <EmailCardContent>
        <EmailHeader>
          <EmailDay>
            <Clock size={16} /> Day {sequence.delay_in_days}
          </EmailDay>
          {isEditing ? (
            <EmailInput
              placeholder="Email Subject (Same thread follow up if empty)"
              value={sequence.subject}
              style={{ width: 400 }}
              onChange={(e) =>
                handleSequenceChange(
                  sequence.clientId,
                  "subject",
                  e.target.value
                )
              }
            />
          ) : (
            <div style={{ width: 400, color: "#6b7280" }}>
              {sequence.subject || "Same thread follow up"}
            </div>
          )}
        </EmailHeader>
        <div style={{ display: "flex", gap: "8px" }}>
          <Button
            variant="outline"
            fullWidth={false}
            onClick={() =>
              setEditingSequence(isEditing ? null : sequence.clientId)
            }
          >
            {isEditing ? "Close Editor" : "Edit Content"}
          </Button>
          <Button
            variant="danger"
            fullWidth={false}
            onClick={() => handleDeleteSequence(sequence.clientId)}
          >
            <Trash2 size={16} />
          </Button>
        </div>
      </EmailCardContent>
      {isEditing && (
        <EmailCardContent>
          <FormGroup>
            <FormLabel>Sequence Number</FormLabel>
            <EmailInput
              type="number"
              value={sequence.seq_number}
              onChange={(e) =>
                handleSequenceChange(
                  sequence.clientId,
                  "seq_number",
                  e.target.value
                )
              }
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Delay (days)</FormLabel>
            <EmailInput
              type="number"
              value={sequence.delay_in_days}
              onChange={(e) =>
                handleSequenceChange(
                  sequence.clientId,
                  "delay_in_days",
                  e.target.value
                )
              }
            />
          </FormGroup>
          <FormGroup style={{ width: "100%" }}>
            <FormLabel>Email Body</FormLabel>
            <Textarea
              value={sequence.email_body}
              onChange={(e) =>
                handleSequenceChange(
                  sequence.clientId,
                  "email_body",
                  e.target.value
                )
              }
              rows={6}
              placeholder="Write your email content..."
            />
          </FormGroup>
          <div style={{ width: 1 }} />
          <Button variant="primary" onClick={() => setEditingSequence(null)}>
            Save
          </Button>
        </EmailCardContent>
      )}
    </EmailCardContainer>
  );
};

export default EmailCard;
