// @ts-nocheck
// Textarea.jsx

import styled from "styled-components";

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 8px;
  border: 1px solid #d1d5db;
  border-radius: 4px;

  &:focus {
    outline: none;
    border-color: #3b82f6;
  }
`;

export default Textarea;
