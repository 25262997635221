import React, { useEffect, useState } from 'react'
import HeaderSavedLeads from '../components/Headers/HeadersSavedLeads'; 
import UserTable from '../components/Table/NewTable';

export default function CampaignList() {
    const columns = [
        { title: "Full Name"},
        { title: "Title"},
        { title: "Email Address"},
        { title: "Company"},
        { title: "Email content"},
        // { title: "LinkedIn URL"},
        // { title: "Company Data"},
    ];

    const [is_loading, setIsLoading] = useState(false);
    const [campaign_list, setCampaignList] = useState([]);
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('actio_user'));
        setIsLoading(true);
        fetch(process.env.REACT_APP_API_URL + '/get_saved_leads', {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${user.id}@@@${user.email}`, // Example: Authorization header with bearer token
                'Content-Type': 'application/json',
            },
            credentials: 'include', // Include credentials (cookies) with the request
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json(); // Parse JSON response asynchronously
        })
        .then((response) => {
            setCampaignList(
                response.map((item) => {
                    const leadData = item;
                    if (leadData){
                        const employmentHistory = JSON.parse(leadData["employment_history"]) || [];
                        return {
                            full_name: leadData["full_name"],
                            email_content: leadData["email_content"],
                            title: employmentHistory.length > 0 && employmentHistory[0]?.title ? employmentHistory[0]?.title : leadData["title"], // Default to 'N/A' if title is not present
                            email: leadData?.email || "-", // Default to 'N/A' if email is not present
                            linkedin_url: leadData["linkedin_url"] || '', // Default to 'N/A' if LinkedIn URL is not present
                            employment_history: employmentHistory.length > 0 && employmentHistory[0]?.organization_name ? employmentHistory[0].organization_name : '', // Check if employment history and organization name exist
                            organization: (() => {
                                try {
                                    const org = JSON.parse(leadData["organization"]);
                                    return org?.website_url || '';
                                } catch (e) {
                                    return ''; // Return empty string if JSON parsing fails
                                }
                            })(),
                            company_linkedin_url: (() => {
                                try {
                                    const org = JSON.parse(leadData["organization"]);
                                    return org?.company_linkedin_url || '';
                                } catch (e) {
                                    return ''; // Return empty string if JSON parsing fails
                                }
                            })(),
                            // leadData["photo_url"]
                            photo_url: (() => {
                                try {
                                    const org = JSON.parse(leadData["linkedin_data"]);
                                    return org?.profilePic || leadData["photo_url"] || '';
                                } catch (e) {
                                    return  leadData["photo_url"] || ''; // Return empty string if JSON parsing fails
                                }
                            })(), // Default to 'N/A' if photo URL is not present
                            organization_logo: (() => {
                                try {
                                    const org = JSON.parse(leadData["organization"]);
                                    return org?.logo_url || '';
                                } catch (e) {
                                    return ''; // Return empty string if JSON parsing fails
                                }
                            })(),
                        };
                        
                        
                    }
                    
                })
            );
        })

        setIsLoading(false);
    },[]);
    return (
        <>
        <HeaderSavedLeads />
        <div style={{padding : "10px", maxHeight : "85vh", "overflowY" : "auto"}}>
        <UserTable data={campaign_list} columns={columns}/>
        </div>
        
        </>
    )
}
