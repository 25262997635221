// @ts-nocheck
// Spinner.jsx

import styled, { keyframes } from "styled-components";
import { Loader2 } from "lucide-react";

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Spinner = styled(Loader2)`
  animation: ${spin} 1s linear infinite;
`;

export default Spinner;
