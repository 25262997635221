// @ts-nocheck
// Sidebar.jsx

import React from "react";
import styled from "styled-components";
import { Plus } from "lucide-react";
import Button from "./Button";
import CampaignList from "./CampaignList";

const SidebarContainer = styled.div``;

const Card = styled.div`
  background-color: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const CardHeader = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e7eb;

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0;
  }
`;

const CardContent = styled.div`
  padding: 16px;
  flex: 1;
  overflow-y: auto;
`;

const Sidebar = ({
  campaigns,
  loadingCampaigns,
  selectedCampaign,
  setSelectedCampaign,
}) => {
  return (
    <SidebarContainer>
      <Card>
        <CardHeader>
          <h2>Campaigns</h2>
          {/* <Button variant="outline" fullWidth={false}>
            <Plus size={16} /> New
          </Button> */}
        </CardHeader>
        <CardContent>
          {loadingCampaigns ? (
            <div>Loading campaigns...</div>
          ) : (
            <CampaignList
              campaigns={campaigns}
              selectedCampaign={selectedCampaign}
              setSelectedCampaign={setSelectedCampaign}
            />
          )}
        </CardContent>
      </Card>
    </SidebarContainer>
  );
};

export default Sidebar;
