// @ts-nocheck
// CampaignList.jsx

import React from "react";
import styled from "styled-components";

const CampaignListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CampaignItem = styled.div`
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.2s;
  background-color: ${({ active }) => (active ? "#f9fafb" : "transparent")};
  border-radius: 8px;
  margin-bottom: 12px;
  &:hover {
    background-color: ${({ active }) => (active ? "#f9fafb" : "#f3f4f6")};
  }
`;

const CampaignHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CampaignName = styled.span`
  font-weight: 500;
  font-size: 14px;
`;

const CampaignStatus = styled.span`
  padding: 4px 8px;
  border-radius: 9999px;
  font-size: 12px;
  text-transform: capitalize;
  background-color: ${({ status }) =>
    status.toLowerCase() === "active" || status.toLowerCase() === "running"
      ? "#d1fae5"
      : "#f3f4f6"};
  color: ${({ status }) =>
    status.toLowerCase() === "active" || status.toLowerCase() === "running"
      ? "#047857"
      : "#6b7280"};
`;

const CampaignEmails = styled.div`
  font-size: 12px;
  color: #6b7280;
  margin-top: 4px;
`;

const CampaignList = ({ campaigns, selectedCampaign, setSelectedCampaign }) => {
  return (
    <CampaignListContainer>
      {campaigns.map((campaign) => (
        <CampaignItem
          key={campaign.id}
          active={selectedCampaign === campaign.id}
          onClick={() => setSelectedCampaign(campaign.id)}
        >
          <CampaignHeader>
            <CampaignName>{campaign.name}</CampaignName>
            <CampaignStatus status={campaign.status}>
              {campaign.status}
            </CampaignStatus>
          </CampaignHeader>
          {/* <CampaignEmails>{campaign.emails || 0} emails</CampaignEmails> */}
        </CampaignItem>
      ))}
    </CampaignListContainer>
  );
};

export default CampaignList;
