// @ts-nocheck
// Schedule.jsx

import axios from "axios";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

const ScheduleGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ScheduleCard = styled.div`
  padding: 24px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
`;

const ScheduleTitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px;
`;

const ScheduleInput = styled.input`
  width: 100%;
  height: 36px;
  padding: 8px;
  border: 1px solid #d1d5db;
  border-radius: 4px;

  &:focus {
    outline: none;
    border-color: #3b82f6;
  }
`;

const DaySelector = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
`;

const DayButton = styled.button<{ selected: boolean }>`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid ${(props) => (props.selected ? "#3b82f6" : "#d1d5db")};
  background: ${(props) => (props.selected ? "#3b82f6" : "white")};
  color: ${(props) => (props.selected ? "white" : "black")};
  cursor: pointer;
`;

// Add new styled button component
const SaveButton = styled.button`
  padding: 12px 24px;
  background: #3b82f6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 24px;

  &:hover {
    background: #2563eb;
  }

  &:disabled {
    background: #93c5fd;
    cursor: not-allowed;
  }
`;

// Add interface for schedule data

const Schedule = ({
  campaignId,
  campaignData,
  reloadCampaigns,
}: {
  campaignId: number;
  campaignData?: Campaign;
  reloadCampaigns: () => void;
}) => {
  const [schedule, setSchedule] = useState<Schedule>({
    timezone: "Europe/London",
    days_of_the_week: [1, 2, 3, 4, 5],
    start_hour: "09:00",
    end_hour: "17:00",
    min_time_btw_emails: 10,
    max_new_leads_per_day: 20,
    schedule_start_time: new Date(Date.now() + 86400000).toISOString(),
  });

  // Add loading state
  const [isLoading, setIsLoading] = useState(false);

  // Add new state for status message
  const [saveStatus, setSaveStatus] = useState<{
    type: "success" | "error";
    message: string;
  } | null>(null);

  useEffect(() => {
    if (campaignData) {
      setSchedule(transformToSchedule(campaignData));
    }
  }, [campaignData]);

  // Update input handlers
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSchedule((prev) => ({
      ...prev,
      schedule_start_time: new Date(e.target.value).toISOString(),
    }));
  };

  // Update handleSubmit to include status messages
  const handleSubmit = async () => {
    setIsLoading(true);
    setSaveStatus(null);
    try {
      const user = JSON.parse(localStorage.getItem("actio_user"));
      const authToken = `Bearer ${user.id}@@@${user.email}`;

      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/campaign/${campaignId}/schedule`,
        {
          schedule,
        },
        { headers: { Authorization: authToken } }
      );
      if (response.status === 200) {
        setSaveStatus({
          type: "success",
          message: "Schedule saved successfully",
        });
        await reloadCampaigns();
      }
    } catch (error) {
      setSaveStatus({ type: "error", message: "Failed to save schedule" });
      console.error("Error updating schedule:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleDay = (day: number) => {
    setSchedule((prev) => ({
      ...prev,
      days_of_the_week: prev.days_of_the_week.includes(day)
        ? prev.days_of_the_week.filter((d) => d !== day)
        : [...prev.days_of_the_week, day].sort(),
    }));
  };

  // Add styled component for status message
  const StatusMessage = styled.span<{ type: "success" | "error" }>`
    margin-left: 16px;
    color: ${(props) => (props.type === "success" ? "#10B981" : "#EF4444")};
    font-size: 14px;
  `;

  return (
    <>
      <ScheduleGrid>
        <ScheduleCard>
          <ScheduleTitle>Launch Date</ScheduleTitle>
          <ScheduleInput
            type="date"
            value={schedule.schedule_start_time.split("T")[0]}
            onChange={handleDateChange}
          />
        </ScheduleCard>
        <ScheduleCard>
          <ScheduleTitle>Send Time</ScheduleTitle>
          <ScheduleInput type="time" />
        </ScheduleCard>
        <ScheduleCard>
          <ScheduleTitle>Timezone</ScheduleTitle>
          <ScheduleInput
            as="select"
            value={schedule.timezone}
            onChange={(e) =>
              setSchedule((prev) => ({ ...prev, timezone: e.target.value }))
            }
          >
            <option value="Europe/London">London (GMT/BST)</option>
            <option value="Europe/Paris">Paris (GMT+1/CET)</option>
            <option value="Europe/Berlin">Berlin (GMT+1/CET)</option>
            <option value="Europe/Madrid">Madrid (GMT+1/CET)</option>
            <option value="Europe/Rome">Rome (GMT+1/CET)</option>
            <option value="Europe/Amsterdam">Amsterdam (GMT+1/CET)</option>
            <option value="Europe/Brussels">Brussels (GMT+1/CET)</option>
            <option value="Europe/Copenhagen">Copenhagen (GMT+1/CET)</option>
            <option value="Europe/Stockholm">Stockholm (GMT+1/CET)</option>
            <option value="Europe/Warsaw">Warsaw (GMT+1/CET)</option>
            <option value="Europe/Athens">Athens (GMT+2/EET)</option>
            <option value="Europe/Istanbul">Istanbul (GMT+3/TRT)</option>
            <option value="America/New_York">New York (GMT-5/EST)</option>
            <option value="America/Chicago">Chicago (GMT-6/CST)</option>
            <option value="America/Denver">Denver (GMT-7/MST)</option>
            <option value="America/Los_Angeles">Los Angeles (GMT-8/PST)</option>
            <option value="America/Toronto">Toronto (GMT-5/EST)</option>
            <option value="Australia/Sydney">Sydney (GMT+11/AEDT)</option>
            <option value="Asia/Tokyo">Tokyo (GMT+9/JST)</option>
            <option value="Asia/Singapore">Singapore (GMT+8/SGT)</option>
            <option value="Asia/Dubai">Dubai (GMT+4/GST)</option>
          </ScheduleInput>
        </ScheduleCard>
        <ScheduleCard>
          <ScheduleTitle>Working Days</ScheduleTitle>
          <DaySelector>
            {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
              (day, index) => (
                <DayButton
                  key={day}
                  selected={schedule.days_of_the_week.includes(index)}
                  onClick={() => toggleDay(index)}
                >
                  {day}
                </DayButton>
              )
            )}
          </DaySelector>
        </ScheduleCard>
        <ScheduleCard>
          <ScheduleTitle>Working Hours</ScheduleTitle>
          <div style={{ marginBottom: "16px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "8px",
                fontSize: "12px",
              }}
            >
              Day Start
            </label>
            <ScheduleInput
              type="time"
              value={schedule.start_hour}
              onChange={(e) =>
                setSchedule((prev) => ({ ...prev, start_hour: e.target.value }))
              }
            />
          </div>
          <div>
            <label
              style={{
                display: "block",
                marginBottom: "8px",
                fontSize: "12px",
              }}
            >
              Day End
            </label>
            <ScheduleInput
              type="time"
              value={schedule.end_hour}
              onChange={(e) =>
                setSchedule((prev) => ({ ...prev, end_hour: e.target.value }))
              }
            />
          </div>
        </ScheduleCard>
        <ScheduleCard>
          <ScheduleTitle>Email Sending Settings</ScheduleTitle>
          <div style={{ marginBottom: "16px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "8px",
                fontSize: "12px",
              }}
            >
              Minimum Time Between Emails (minutes)
            </label>
            <ScheduleInput
              type="number"
              min="1"
              max="1440"
              value={schedule.min_time_btw_emails}
              onChange={(e) =>
                setSchedule((prev) => ({
                  ...prev,
                  min_time_btw_emails: parseInt(e.target.value),
                }))
              }
            />
          </div>
          <div>
            <label
              style={{
                display: "block",
                marginBottom: "8px",
                fontSize: "12px",
              }}
            >
              Maximum New Leads Per Day
            </label>
            <ScheduleInput
              type="number"
              min="1"
              max="1000"
              value={schedule.max_new_leads_per_day}
              onChange={(e) =>
                setSchedule((prev) => ({
                  ...prev,
                  max_new_leads_per_day: parseInt(e.target.value),
                }))
              }
            />
          </div>
        </ScheduleCard>
      </ScheduleGrid>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 20,
          flexDirection: "column",
        }}
      >
        <SaveButton onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? "Saving..." : "Save Schedule"}
        </SaveButton>
        {saveStatus && (
          <StatusMessage type={saveStatus.type}>
            {saveStatus.message}
          </StatusMessage>
        )}
      </div>
    </>
  );
};

/**
 * Defines the structure of the input campaign object.
 */
export interface Campaign {
  id: number;
  user_id: number;
  created_at: string;
  updated_at: string;
  status: "DRAFTED" | "ACTIVE" | "COMPLETED" | "STOPPED" | "PAUSED";
  name: string;
  track_settings:
    | "DONT_EMAIL_OPEN"
    | "DONT_LINK_CLICK"
    | "DONT_REPLY_TO_AN_EMAIL";
  scheduler_cron_value: string;
  min_time_btwn_emails: number; // minutes
  max_leads_per_day: number;
  parent_campaign_id: number | null;
  stop_lead_settings: "REPLY_TO_AN_EMAIL" | "CLICK_ON_A_LINK" | "OPEN_AN_EMAIL";
  unsubscribe_text: string;
  client_id: number | null;
}

/**
 * Defines the structure of the schedule object.
 */
export interface Schedule {
  timezone: string;
  days_of_the_week: number[]; // [0,1,2,3,4,5,6]
  start_hour: string; // "09:00"
  end_hour: string; // "18:00"
  min_time_btw_emails: number; // time in minutes between emails
  max_new_leads_per_day: number; // max new leads per day
  schedule_start_time: string; // Standard ISO format
}

/**
 * Transforms the input campaign object to the desired schedule object.
 * @param {Campaign} campaign - The input campaign object.
 * @returns {Schedule} - The transformed schedule object.
 * @throws Will throw an error if scheduler_cron_value cannot be parsed.
 */
function transformToSchedule(campaign: Campaign): Schedule {
  const { scheduler_cron_value, min_time_btwn_emails, max_leads_per_day } =
    campaign;

  let cronValue: {
    tz: string;
    days: number[];
    startHour: string;
    endHour: string;
  };

  try {
    // Replace single quotes with double quotes to make it valid JSON

    cronValue = scheduler_cron_value || {};
  } catch (error) {
    console.error("Error parsing scheduler_cron_value:", error);
    throw new Error("Invalid scheduler_cron_value format.");
  }

  const schedule: Schedule = {
    timezone: cronValue?.tz || "Europe/London",
    days_of_the_week: cronValue?.days || [1, 2, 3, 4, 5],
    start_hour: cronValue?.startHour || "09:00",
    end_hour: cronValue?.endHour || "17:00",
    min_time_btw_emails: min_time_btwn_emails,
    max_new_leads_per_day: max_leads_per_day,
    schedule_start_time: new Date().toISOString(), // Current time in ISO format
  };

  return schedule;
}

export default Schedule;
