import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // For React Router v6
import HeaderSavedLeads from '../components/Headers/HeadersSavedLeads';
import PendingTable from '../components/Table/PendingTable';
import mainLoader from "../assets/images/AnimationLoader.json"
import Lottie from "lottie-react";
export default function LeadSearchHistory() {
    const [leadData, setLeadData] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const columns = [
        { title: "Locations" },
        { title: "Employees Ranges" },
        { title: "Titles" },
        { title: "Keywords" },
        { title: "Date" },
    ];

    function convertUTCToLocal(utcString) {
        const utcDate = new Date(utcString + 'Z'); // Append 'Z' to indicate UTC time
        return utcDate.toLocaleString(); // Convert to local time as string
    }

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('actio_user'));
        setIsLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/lead_search_history`, {
            headers: {
                Authorization: `Bearer ${user.id}@@@${user.email}`, // Example: Authorization header with bearer token
            }
        })
            .then((response) => {
                setLeadData(response.data);
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
            });
    }, []);

    const handleRowClick = (id) => {

        navigate(`../build-campaign?id=${id}`);
    };

    return (
        <>
            <HeaderSavedLeads />
            <div style={{ padding: "10px" }}>
                {isLoading ? (<div className='loader-container' style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <Lottie className="loader-animation" animationData={mainLoader} loop={true} style={{
                        width: "250px",
                        height: "250px"
                    }} />
                </div>) : <PendingTable data={leadData} columns={columns} />}
            </div>

        </>
    );
}
