import React, { useState } from 'react';
import { Row, Col, Image, Button, Alert, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Card from '../components/Card';

import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { setProfile } from '../store/userSlice';
import axios from 'axios';

import signInBackground from "../assets/images/background_pattern.png"
import googleLogo from "../assets/images/google_icon.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer, toast } from 'react-toastify';
import CarouselComponent from '../components/CarasoulComponet/CarouselComponent';
// import { faGoogle } from '@fortawesome/free-brands-svg-icons';  // Import Google icon

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loginFail, setLoginFail] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false)

    const login = useGoogleLogin({
        onSuccess: (user) => {
            fetch(process.env.REACT_APP_API_URL + '/auth?token=' + user.access_token, {
                credentials: 'include',
            })
                .then((response) => response.json())
                .then((user) => {
                    if (user === 'Unauthorized') {
                        setLoginFail(true);
                    } else {
                        localStorage.setItem('actio_user', JSON.stringify(user));
                        dispatch(setProfile(user));
                        navigate('../');
                    }
                });
        },
        onError: (error) => console.log('Login Failed:', error),
    });

    const handleEmailLogin = (e) => {
        e.preventDefault();
        setLoginLoading(true);
    
        axios.post(`${process.env.REACT_APP_API_URL}/users/login`, {
            email,
            password,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            const user = response.data
            localStorage.setItem('actio_user', JSON.stringify(user));
            localStorage.removeItem('actio_user_campaign_data')
            dispatch(setProfile(user));
            navigate('../');
        })
        .catch((error) => {
            const errMsg = error?.response?.data?.detail?.message
            toast.error(errMsg)
        })
        .finally(() => {
            setLoginLoading(false);
        });
    };
    

    return (
        <>
            <section className="login-content">
            <ToastContainer />
                <Row className="m-0 align-items-center bg-white vh-100">
                    <Col md="6">
                        <Row className="justify-content-center">
                            <Col md="10">
                                <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 mt-n6 auth-card">
                                    <Card.Body>
                                        {/* <Link to="#" className="navbar-brand d-flex align-items-center mb-3">
                                            <svg width="30" className="text-primary" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="-0.757324" y="19.2427" width="28" height="4" rx="2" transform="rotate(-45 -0.757324 19.2427)" fill="currentColor" />
                                                <rect x="7.72803" y="27.728" width="28" height="4" rx="2" transform="rotate(-45 7.72803 27.728)" fill="currentColor" />
                                                <rect x="10.5366" y="16.3945" width="16" height="4" rx="2" transform="rotate(45 10.5366 16.3945)" fill="currentColor" />
                                                <rect x="10.5562" y="-0.556152" width="28" height="4" rx="2" transform="rotate(45 10.5562 -0.556152)" fill="currentColor" />
                                            </svg>
                                            <h4 className="logo-title ms-3">Email Campaign</h4>
                                        </Link> */}
                                        <h3 className="mb-2 text-center">Welcome Back</h3>
                                        <h5 className='fs-6 mb-4 fw-normal text-center'>Please enter your details.</h5>
                                        <Form onSubmit={handleEmailLogin}>
                                        <Form.Group controlId="formEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter your email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formPassword" className="mt-3">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formRememberMe" className="mt-3 d-flex justify-content-between align-items-center">
                                            <Form.Check
                                                type="checkbox"
                                                label="Remember me"
                                                checked={rememberMe}
                                                onChange={(e) => setRememberMe(e.target.checked)}
                                            />
                                            <a 
                                                href="/forgot-password" 
                                                className="text-primary"
                                                style={{ cursor: 'pointer' }}
                                            >
                                                Forgot password?
                                            </a>
                                        </Form.Group>


                                        <div className="text-center mt-3">
                                            <Button
                                                disabled={loginLoading}
                                                // variant="primary"
                                                style={{ 
                                                    backgroundColor: '#007AFF',
                                                    display : 'flex',
                                                    justifyContent : 'center',
                                                }}
                                                type="submit"
                                                className="w-100"
                                            >
                                                Sign In
                                            </Button>
                                        </div>
                                    </Form>

                                        <div className="text-center mt-3">
                                            <Button variant="outline-primary" onClick={login} className='w-100' style={{display : 'flex',
                                                    justifyContent : 'center'}}>
                                            <Image style={{ width: '20px', marginRight: '10px'}} src={googleLogo} className="Image-fluid gradient-main" alt="images" />
                                                <span>Sign in with Google</span>
                                            </Button>
                                        </div>
                                        <div className="text-center mt-3">
                                            <span>
                                                Don't have an account?{' '}
                                                <Button
                                                    variant="link"
                                                    as={Link}
                                                    to="/signup"
                                                    style={{ padding: 0,
                                                        display : 'flex',
                                                        justifyContent : 'center', }} // Remove padding if needed
                                                >
                                                    Sign Up
                                                </Button>
                                            </span>
                                        </div>

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <div className="sign-bg">
                            {/* <svg width="280" height="230" viewBox="0 0 431 398" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.05">
                                    <rect x="-157.085" y="193.773" width="543" height="77.5714" rx="38.7857" transform="rotate(-45 -157.085 193.773)" fill="#3B8AFF" />
                                    <rect x="7.46875" y="358.327" width="543" height="77.5714" rx="38.7857" transform="rotate(-45 7.46875 358.327)" fill="#3B8AFF" />
                                    <rect x="61.9355" y="138.545" width="310.286" height="77.5714" rx="38.7857" transform="rotate(45 61.9355 138.545)" fill="#3B8AFF" />
                                    <rect x="62.3154" y="-190.173" width="543" height="77.5714" rx="38.7857" transform="rotate(45 62.3154 -190.173)" fill="#3B8AFF" />
                                </g>
                            </svg> */}
                            {/* <Image src={signInBackground} className="Image-fluid" alt="images" /> */}
                        </div>
                    </Col>
                    <Col md="6" className="d-md-block d-none p-0 vh-100 overflow-hidden">
                        {/* <Image src={auth1} className="Image-fluid gradient-main animated-scaleX" alt="images" /> */}
                        <CarouselComponent />
                    </Col>
                </Row>
            </section>
        </>
    );
};

export default Login;
