export const FUNDING = [
    {
        "value": "1",
        "display_name": "Angel",
        "count": 30174
      },
        {
          "value": "0",
          "display_name": "Seed",
          "count": 477627
        },
        
        
        {
          "value": "2",
          "display_name": "Series A",
          "count": 651283
        },
        {
          "value": "3",
          "display_name": "Series B",
          "count": 649784
        },
        {
          "value": "4",
          "display_name": "Series C",
          "count": 426608
        },
        {
          "value": "5",
          "display_name": "Series D",
          "count": 240949
        },
        {
          "value": "6",
          "display_name": "Series E",
          "count": 203802
        },
        {
          "value": "7",
          "display_name": "Series F",
          "count": 89426
        },
        {
          "value": "13",
          "display_name": "Debt Financing",
          "count": 2131495
        },
        {
          "value": "14",
          "display_name": "Equity Crowdfunding",
          "count": 26035
        },
        {
          "value": "15",
          "display_name": "Convertible Note",
          "count": 28965
        },
        {
          "value": "11",
          "display_name": "Private Equity",
          "count": 772816
        },
        {
          "value": "12",
          "display_name": "Other",
          "count": 7986780
        },
        {
            "value": "10",
            "display_name": "Venture (Round not Specified)",
            "count": 768546
          },
      ]