import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import styles from './Header.module.css'; // Importing the CSS module
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch, faUpload} from '@fortawesome/free-solid-svg-icons';

const HeadersPendingEmails = ({source, setSource, pendingEmails}) => {
  const handleSourceChange = (updatedSource) => {
    setSource(updatedSource)
    
  };
  return (
    <div className={styles.headerContainerPendingEmail}>
      <Container fluid>
        <Row className="align-items-center justify-content-between">
          <Col md={8}>
          <h2 className={styles.headerTitle}>
              Pending Emails {pendingEmails ? `(${pendingEmails > 1000 ? '1,000+' : pendingEmails})` : ""}
            </h2>
            <p className={styles.headerSubtitle}>
              Validate the emails here to add to queue
            </p>
          </Col>          
        </Row>
      </Container>
    </div>
  );
};

export default HeadersPendingEmails;
