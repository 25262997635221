// import React, { useState } from 'react';
// import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TablePagination, Typography } from '@mui/material';
// import { Delete as DeleteIcon } from '@mui/icons-material';
// import Papa from 'papaparse';

// const CsvUpload = () => {
//   const [rows, setRows] = useState([]);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);
  
//   // Handle file upload and parsing
//   const handleFileUpload = (e) => {
//     const file = e.target.files[0];
//     if (!file) return;
    
//     console.log('File selected: ', file);  // Debug log
    
//     Papa.parse(file, {
//       header: true,
//       skipEmptyLines: true,
//       complete: (result) => {
//         console.log('Parsed result: ', result);  // Debug log
//         const data = result.data;
        
//         // Filter rows that have required fields
//         const validRows = data.filter(row => row['First Name'] && row['Last Name'] && row['User LinkedIn URL']);
//         console.log('Valid rows: ', validRows);  // Debug log for valid rows
        
//         // Check if required columns are present
//         if (!data[0].hasOwnProperty('First Name') || 
//             !data[0].hasOwnProperty('Last Name') || 
//             !data[0].hasOwnProperty('User LinkedIn URL')) {
//           alert('Missing required columns: First Name, Last Name, User LinkedIn URL');
//         } else {
//           setRows(validRows);
//         }
//       },
//       error: (error) => {
//         console.error("Parsing error: ", error);  // Debug log for errors
//       }
//     });
//   };
  
//   // Delete a row
//   const handleDelete = (index) => {
//     const updatedRows = rows.filter((row, i) => i !== index);
//     setRows(updatedRows);
//   };

//   // Pagination handlers
//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   return (
//     <div>
//       <Typography variant="h6" gutterBottom>Upload CSV</Typography>
      
//       {/* Upload CSV Button */}
//       <Button variant="contained" component="label">
//         Upload CSV
//         <input
//           type="file"
//           accept=".csv"
//           hidden
//           onChange={handleFileUpload} // Ensure this is called
//         />
//       </Button>

//       {/* Ensure the input is visible for testing purposes */}
//       {/* <input
//         type="file"
//         accept=".csv"
//         onChange={handleFileUpload}
//         style={{ display: 'block', marginTop: '10px' }}  // Visible for testing
//       /> */}

//       {rows.length > 0 && (
//         <>
//           <TableContainer component={Paper} style={{ marginTop: 20 }}>
//             <Table>
//               <TableHead>
//                 <TableRow>
//                   <TableCell>First Name</TableCell>
//                   <TableCell>Last Name</TableCell>
//                   <TableCell>LinkedIn URL</TableCell>
//                   <TableCell>Actions</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
//                   <TableRow key={index}>
//                     <TableCell>{row['First Name']}</TableCell>
//                     <TableCell>{row['Last Name']}</TableCell>
//                     <TableCell>{row['User LinkedIn URL']}</TableCell>
//                     <TableCell>
//                       <IconButton onClick={() => handleDelete(page * rowsPerPage + index)}>
//                         <DeleteIcon />
//                       </IconButton>
//                     </TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>

//           <TablePagination
//             rowsPerPageOptions={[5, 10, 25]}
//             component="div"
//             count={rows.length}
//             rowsPerPage={rowsPerPage}
//             page={page}
//             onPageChange={handleChangePage}
//             onRowsPerPageChange={handleChangeRowsPerPage}
//           />
//         </>
//       )}
//     </div>
//   );
// };

// export default CsvUpload;

import React, { useState } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TablePagination, Typography, CircularProgress } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import Papa from 'papaparse';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import { faChartPie } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import {  Spinner, Modal } from 'react-bootstrap';

const CsvUpload = () => {
  const [rows, setRows] = useState([]);
  const [showUpload, setShowUpload] = useState(true)
//   const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [processedData, setProcessedData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [page, setPage] = useState(0);
// const rowsPerPage = 10; // Set the number of rows per page (adjust as needed)

// Go to the previous page
const handlePreviousPage = () => {
  setPage((prevPage) => Math.max(prevPage - 1, 0));
};

// Go to the next page
const handleNextPage = () => {
  if (page < Math.ceil(rows.length / rowsPerPage) - 1) {
    setPage((prevPage) => prevPage + 1);
  }
};

  // Handle file upload and parsing
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        const data = result.data;

        // Filter valid rows that have First Name, Last Name, and User LinkedIn URL
        const validRows = data.filter(row => 
          row['First Name'] && 
          row['Last Name'] && 
          row['User LinkedIn URL']
        );

        // Check if required columns are present (without requiring Email)
        if (!data[0].hasOwnProperty('First Name') || 
            !data[0].hasOwnProperty('Last Name') || 
            !data[0].hasOwnProperty('User LinkedIn URL')) {
          alert('Missing required columns: First Name, Last Name, or User LinkedIn URL');
        } else {
          // Include optional Email if available
          const processedRows = validRows.map(row => ({
            first_name: row['First Name'],
            last_name: row['Last Name'],
            linkedin_url: row['User LinkedIn URL'],
            email: row['Email'] || '', // Optional email field
            title: row['Title'] || '', // Any other field can be added optionally
            company_name: row['Company Name'] || '',
            website: row['Website'] || '',
            company_linkedin_url: row['Company Linkedin Url'] || ''
          }));
          console.log(processedRows)

          setRows(processedRows);
          setShowUpload(false)
        }
      },
      error: (error) => {
        console.error("Parsing error: ", error);
      }
    });
};



  // Delete a row
  const handleDelete = (index) => {
    const updatedRows = rows.filter((row, i) => i !== index);
    setRows(updatedRows);
  };

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

//   const handleProcessData = () => {
//     setLoading(true)
//     const formattedData = rows.map(row => ({
//       first_name: row['first_name'],
//       name: row['first_name'] + " " + row["last_name"], // Assuming 'name' is the same as 'first_name'
//       last_name: row['last_name'],
//       Title: row['Title'] || '',
//       linkedin_url: row['linkedin_url'],
//       'Company Name': row['Company Name'] || '',
//       Website: row['Website'] || '',
//       'Company Linkedin Url': row['Company Linkedin Url'] || '',
//       // Add the email field only if present
//       ...(row['Email'] && { Email: row['Email'] })
//     }));
    
//     const payload = {"data" : formattedData}

//     const url = `${process.env.REACT_APP_API_URL}/find_people?source=csv`;
//     const user = JSON.parse(localStorage.getItem('actio_user'));
//     // axios.post(url, payload, {
//     //     headers: {
//     //       'Authorization': `Bearer ${user.id}@@@${user.email}`, // Replace with your token if needed
//     //       'Content-Type': 'application/json',    // Specify the content type
//     //       // Add any other headers here
//     //     }
//     //   })
//     //     .then(response => {
//     //       console.log('Success:', response.data);
//     //       setLoading(false)
//     //       // You can handle success response here (e.g., display a success message)
//     //     })
//     //     .catch(error => {
//     //       console.error('Error:', error);
//     //       setLoading(false)
//     //       // Handle error here (e.g., display an error message)
//     //     });
    
//     //   // Optionally, you can also save the processed data in state if needed
//     //   setProcessedData(formattedData);
//     // //   setLoading(false)
//     try {
//         const response = await axios.post(url, data, {
//             headers: {
//                 Authorization: `Bearer ${user.id}@@@${user.email}`, // Example: Authorization header with bearer token
//             }
//         });

//         setTotalEntries(response.data.page_data.total_entries);
//         setIsFormSubmitted(true); // Set form submission state to true
//         setLoading(false);

//         const leadId = response.data.lead_search_uuid;
//         let page = 1; // Starting page for fetching emails
//         let emailInterval;

//         // Function to periodically fetch emails and redirect if needed
//         const checkEmails = async () => {
//             const emails = await fetchEmails('status', page, leadId);

//             if (emails >= 2) {
//                 // Clear the interval and redirect if emails are found
//                 clearInterval(emailInterval);
//                 window.location.href = `/lead-history/${leadId}`;
//             } else {
//                 // No emails found yet, increment page for next call
//                 page += 1;
//             }
//         };

//         // Set up an interval to check emails every 8 seconds
//         emailInterval = setInterval(checkEmails, 3000);


//         return true; // Indicate success
//     } catch (error) {
//         console.log(error);
//         setLoading(false)
//         toast.error(error.response.data.detail);
//         return false; // Indicate failure
//     }
//   };
  


const fetchEmails = async (status, page, leadId) => {
    const pageSize = 1; // Adjust the page size if needed
    const url = `${process.env.REACT_APP_API_URL}/lead_data/${leadId}?page=${page}&page_size=${pageSize}`;
    const user = JSON.parse(localStorage.getItem('actio_user'));
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${user.id}@@@${user.email}`,
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      return data.data.total_items || 0;
    } catch (error) {
      toast.error('Error fetching emails');
      console.error('Error fetching emails:', error);
      return [];
    }
  };

const handleProcessData = async () => {
    setLoading(true);

    // Format the data rows
    const formattedData = rows.map(row => ({
      first_name: row['first_name'],
      name: row['first_name'] + " " + row["last_name"], // Assuming 'name' is the same as 'first_name'
      last_name: row['last_name'],
      Title: row['Title'] || '',
      linkedin_url: row['linkedin_url'],
      'Company Name': row['Company Name'] || '',
      Website: row['Website'] || '',
      'Company Linkedin Url': row['Company Linkedin Url'] || '',
      // Add the email field only if present
      ...(row['Email'] && { Email: row['Email'] })
    }));

    const payload = { data: formattedData };
    const url = `${process.env.REACT_APP_API_URL}/find_people?source=csv`;
    const user = JSON.parse(localStorage.getItem('actio_user'));

    try {
        // Make the API request
        const response = await axios.post(url, payload, {
            headers: {
                'Authorization': `Bearer ${user.id}@@@${user.email}`, // Example: Authorization header with bearer token
                'Content-Type': 'application/json',
            }
        });

        // Handle success response
        setIsFormSubmitted(true); // Set form submission state to true
        setLoading(false);

        const leadId = response.data.lead_search_uuid;
        let page = 1; // Starting page for fetching emails
        let emailInterval;

        // Function to periodically fetch emails and redirect if needed
        const checkEmails = async () => {
            try {
                const emails = await fetchEmails('status', page, leadId);
                if (emails >= 2) {
                    // Clear the interval and redirect if emails are found
                    clearInterval(emailInterval);
                    window.location.href = `/lead-history/${leadId}`;
                } else {
                    // No emails found yet, increment page for next call
                    page = 1;
                }
            } catch (error) {
                console.error('Error fetching emails:', error);
                clearInterval(emailInterval); // Clear interval if there's an error
            }
        };

        // Set up an interval to check emails every 3 seconds
        emailInterval = setInterval(checkEmails, 3000);

        return true; // Indicate success

    } catch (error) {
        console.error('Error:', error);
        setLoading(false);
        if (error.response && error.response.data && error.response.data.detail) {
            toast.error(error.response.data.detail);
        } else {
            toast.error("An error occurred while processing the data.");
        }
        return false; // Indicate failure
    }
};



  return (
    
    // <div style={{
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     // height: "100vh",
    //     marginTop:"10%"
    // }}>
        <div
  style={
    isFormSubmitted
      ? {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
     // Example styles when form is submitted
          marginTop: "10%"
        }
      : {
        }
  }
>
        {isFormSubmitted ? (
    <div style={{
      display: "flex",
      border: "1px solid #EAECF0",
      background: "#fff",
      fontSize: "0.9rem",
      borderRadius: "10px",
      justifyContent : "center",
      width: "50%",
      alignItems : "center"
    }}>
      <div style={{
        textAlign: "center",
        padding: "2rem",
        borderRadius: "10px",
        marginTop: "2rem",
        width: "500px"
      }}>
        <div style={{ marginBottom: "1.5rem" }}>
          <Spinner animation="border" variant="primary" />
        </div>
        <h3 style={{
          color: "#007bff",
          fontSize: "2rem",
          marginBottom: "1rem"
        }}>Email generation is In Progress</h3>
        <p style={{
          fontSize: "0.8rem",
          color: "#666"
        }}>
          We're now processing your request. Please hang tight for a few moments...
        </p>
        <p style={{
          fontSize: "0.8rem",
          color: "#666"
        }}>
          You will be redirected shortly.
        </p>
      </div>
    </div>
  ): ""}

    
    {!isFormSubmitted ? 
    <div>
      {/* {showUpload ?
      <div style={{ marginTop : "30px", textAlign: "center"}}>
      <Typography variant="h6" gutterBottom>Upload CSV</Typography>
       <Button
       variant="contained"
       component="label"
     >
       Upload CSV
       <input
         type="file"
         accept=".csv"
         hidden
         onChange={handleFileUpload}
       />
     </Button> 
     </div>: ""}  */}
     {showUpload ? (
  <div
    onDragOver={(e) => e.preventDefault()}
    onDrop={(e) => {
      e.preventDefault();
      const files = e.dataTransfer.files;
      handleFileUpload({ target: { files } });
    }}
    style={{
      marginTop: "30px",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '80vh',
      border: '2px dashed #ccc',
      borderRadius: '8px',
      backgroundColor: '#fafafa',
      textAlign: 'center',
      transition: 'background-color 0.3s ease',
      cursor: 'pointer'
    }}
  >
    <div>
      <Typography variant="h6" gutterBottom>
        Drag & Drop a CSV or Click to Upload
      </Typography>
      <Button
        variant="contained"
        component="label"
        style={{ marginTop: '20px' }}
      >
        Upload CSV Manually
        <input
          type="file"
          accept=".csv"
          hidden
          onChange={handleFileUpload}
        />
      </Button>
    </div>
  </div>
) : ""}


     

      {rows.length > 0 && (
        <>
          {/* <TableContainer component={Paper} style={{ marginTop: 20 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>LinkedIn URL</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row['first_name']}</TableCell>
                    <TableCell>{row['last_name']}</TableCell>
                    <TableCell>{row['linkedin_url']}</TableCell>
                    <TableCell>{row['email']}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDelete(page * rowsPerPage + index)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}

<div style={styles.tableContainer}>
  <table style={styles.table}>
    <thead>
      <tr>
        <th style={styles.tableHeader}>First Name</th>
        <th style={styles.tableHeader}>Last Name</th>
        <th style={styles.tableHeader}>LinkedIn URL</th>
        <th style={styles.tableHeader}>Email</th>
        <th style={styles.tableHeader}>Actions</th>
      </tr>
    </thead>
    <tbody>
      {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
        <tr 
          key={index}
          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#F9FAFB'}
          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''} 
        >
          <td style={styles.td}>{row['first_name']}</td>
          <td style={styles.td}>{row['last_name']}</td>
          <td style={styles.td}>{row['linkedin_url']}</td>
          <td style={styles.td}>{row['email']}</td>
          <td style={styles.td}>
            <button 
              style={{ ...styles.button, padding: '5px', backgroundColor: '#dc3545' }} 
              onClick={() => handleDelete(page * rowsPerPage + index)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  <div style={styles.pagination}>
    <button 
      onClick={handlePreviousPage} 
      disabled={page === 0} 
      style={styles.button}
    >
      <FontAwesomeIcon icon={faArrowLeft} />
      <span style={{ marginLeft: "8px" }}>Previous</span>
    </button>
    <span>Page {page + 1} of {Math.ceil(rows.length / rowsPerPage)}</span>
    <button 
      onClick={handleNextPage} 
      disabled={page === Math.ceil(rows.length / rowsPerPage) - 1} 
      style={styles.button}
    >
      <span style={{ marginRight: "8px" }}>Next</span>
      <FontAwesomeIcon icon={faArrowRight} />
    </button>
  </div>
</div>
<div style={{width : "100%", display : "flex", justifyContent : "center"}}>
<Button
      variant="contained"
      color="primary"
      style={{ marginTop: 20 }}
      onClick={handleProcessData}
      disabled={loading} // Disable the button while loading
    >
      {loading ? <CircularProgress size={24} color="inherit" /> : 'Process Data'}
    </Button>


</div>

          
          {/* {processedData && (
            <div style={{ marginTop: 20 }}>
              <Typography variant="h6">Processed Data</Typography>
              <pre>{JSON.stringify(processedData, null, 2)}</pre>
            </div>
          )} */}
        </>
      )}
    </div>
     : ""}
    </div>
        
  );
};

const styles = {
    tableContainer: {
      width: "100%",
      margin: "0 auto",
      padding: "10px",
    },
    table: {
      width: "100%",
      borderRadius: "10px", 
      border: "1px solid #EAECF0",
      backgroundColor : "white"
    },
    tableHeader: {
      textAlign: "left",
      padding: "10px",
      fontSize: "0.9rem",
      color: "#475467",
      borderBottom: "1px solid #EAECF0"
    },
    td: {
      padding: "10px",
      borderBottom: "1px solid #ddd",
      fontSize: "0.9rem",
      maxWidth: "150px",
      wordWrap: "break-word", 
      whiteSpace: "normal",
      color: "#475467"
    },
    pagination: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "10px"
    },
    button: {
      padding: "8px 12px",
      fontSize: "0.9rem",
      backgroundColor: "#007BFF",
      color: "white",
      border: "none",
      cursor: "pointer",
      borderRadius: "4px"
    },
    successTitle: {
        "color": "#007bff",
        "fontSize": "2rem",
        "marginBottom": "1rem"
      },
      successText: {
        "fontSize": "1rem",
        "marginBottom": "0.5rem"
      },
      secondaryText: {
        "fontSize": "0.8rem",
        "color": "#666"
      },
      formContainerSubmittedstyles: {
        "display": "flex",
        "border": "1px solid #EAECF0",
        "background": "#fff",
        "fontSize": "0.9rem",
        "borderRadius": "10px"
      },
    successMessage: {
        "textAlign": "center",
        "padding": "2rem",
        "borderRadius": "10px",
        "marginTop": "2rem",
        "width": "500px"
      },
      loaderContainer: {
        "marginBottom": "1.5rem"
      }

  };

export default CsvUpload;
