import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import Lottie from "lottie-react";
import mailBoxAnimation from "../assets/images/Animation - 1726809812574.json";
import HeaderMailBox from '../components/Headers/HeadersMailBox';

export default function MailBox() {
    const [isLoading, setIsLoading] = useState(true);
    const [campaignList, setCampaignList] = useState([]);
    const [campaignId, setCampaignId] = useState(null);
    const navigate = useNavigate();

    const handleRowClick = (campaign) => {
        navigate(`/mail-box/${campaign.id}`);
    };

    const goToHomePage = () => {
        navigate('/'); // Replace with your homepage route
    };

    useEffect(() => {
        setIsLoading(true);
        const user = JSON.parse(localStorage.getItem('actio_user'));

        // Fetch campaign list
        axios.get(process.env.REACT_APP_API_URL + '/campaign_list', {
            headers: {
                Authorization: `Bearer ${user.id}@@@${user.email}`,
            }
        })
        .then((response) => {
            setIsLoading(false);
            setCampaignList(response.data);

            // If there are campaigns, set the first campaign and navigate to its mailbox
            if (response.data.length > 0) {
                setCampaignId(response.data[0]["id"]);
                navigate(`/mail-box/${response.data[0]["id"]}`);
            }
            else{
              setIsLoading(false)

            }
        })
        .catch((error) => {
            setIsLoading(false);
            console.error('Error fetching campaign list:', error);
        });
    }, [navigate]);

    return (
      <>
        <HeaderMailBox />
        <div className='mail-box-container'>
            {/* Loading Animation */}
            {isLoading ? (
                <Lottie className="mail-box-animation" animationData={mailBoxAnimation} loop={true} />
            ) : (
                // If there are campaigns, render the campaign list
                campaignList.length > 0 ? (
                    <div>
                        {/* Render campaigns here */}
                    </div>
                ) : (
                    // If no campaigns are available, show the message and button to go to home
                    <div className="no-campaign-message"
                    style={{display : "flex", flexDirection : "column", justifyContent : "center", alignItems : "center"}}>
                        <p>You don't have any campaigns. Please start searching and approving mails to start a campaign.</p>
                        <Button 
                        style={{borderRadius : "8px"}}
                          onClick={goToHomePage} variant="primary">Go to Home</Button>
                    </div>
                )
            )}
        </div>
      </>
    );
}
