// EmailManager.tsx
import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashCan,
  faSave,
  faRotate,
  faCircleCheck,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import generateMailMagic from '../../assets/images/star.png';
import DefaultProfile from '../../assets/images/default_profile.svg';
import linkedinSolid from '../../assets/images/linkedin_solid.png';
import DefaultCompLogo from '../../assets/images/DefaultCompLogo.png';
import externalLink from '../../assets/images/external_link.png';
import './NewEmailManager.css';

const EmailManager = ({ leadId, setPendingEmails }) => {
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [editing, setEditing] = useState(false);
  const [emailContent, setEmailContent] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingRefetch, setLoadingRefetch] = useState(false);
  const [deleteEmailId, setDeleteEmailId] = useState(null);
  const [currentTab, setCurrentTab] = useState('user');
  const [disableNavigation, setDisableNavigation] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(true);
  const [showRewritePopUp, setShowRewritePopUp] = useState(false);
  const [inputText, setInputText] = useState('');

  const user = JSON.parse(localStorage.getItem('actio_user'));
  const authToken = `Bearer ${user.id}@@@${user.email}`;

  // Fetch functions here...

  const fetchEmails = async (status, page, leadId) => {
    const pageSize = 1;
    const url = `${process.env.REACT_APP_API_URL}/lead_data/${leadId}?page=${page}&page_size=${pageSize}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: authToken,
          'Content-Type': 'application/json',
        },

      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      fetchEmailsCount(leadId);
      return data.data || [];
    } catch (error) {
      setDisableNavigation(false);
      setIsLoadingMore(false);
      console.error('Error fetching emails:', error);
      return [];
    }
  };

  const fetchEmailsCount = async (leadId) => {
    const url = `${process.env.REACT_APP_API_URL}/lead_data/${leadId}/count`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: authToken,
          'Content-Type': 'application/json',
        },

      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setPendingEmails(data.pending);
    } catch (error) {
      console.error('Error fetching emails:', error);
      return [];
    }
  };

  const deleteEmail = async (id) => {
    const url = `${process.env.REACT_APP_API_URL}/email-lead/${id}`;

    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: authToken,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      toast.error('Error deleting emails');
      console.error('Error deleting email:', error);
      throw error;
    }
  };

  const updateEmail = async (id, content) => {
    const url = `${process.env.REACT_APP_API_URL}/email-lead/${id}`;

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          Authorization: authToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email_content: content }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      toast.error('Error updating email');
      console.error('Error updating email:', error);
      throw error;
    }
  };

  const approveEmail = async (id) => {
    const url = `${process.env.REACT_APP_API_URL}/email-lead/${id}/approve`;

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          Authorization: authToken,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      toast.error('Please add email to your account to start sending mails');
      console.error('Please add email to your account to start sending mails');
      throw error;
    }
  };

  const refetchEmail = async (id, payload) => {
    const url = `${process.env.REACT_APP_API_URL}/fetch-email-content/${id}`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: authToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ lead: payload }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      toast.error('Error refetching email');
      console.error('Error refetching email:', error);
      setLoadingRefetch(false);
      throw error;
    }
  };

  useEffect(() => {
    loadEmails(1);
  }, []);

  const loadEmails = async (currentPage) => {
    setDisableNavigation(true);
    const fetchWithRetry = async () => {
      const newEmailsData = await fetchEmails('approved', currentPage, leadId);
      const newEmails = newEmailsData?.data;
      if (newEmails.length > 0) {
        setEmail(newEmails[0]);
        setEmails([newEmails[0]]);
        setPage(currentPage);
        setHasMore(true);
        setEmailContent(newEmails[0].scrapped_data.email_content || '');
        setEmailSubject(newEmails[0].scrapped_data.subject || '');
        setDisableNavigation(false);
        setIsLoadingMore(false);
      } else {
        const statusData = newEmailsData.status_count;
        if ('in_progress' in statusData) {
          setDisableNavigation(false);
          setIsLoadingMore(true);
          setTimeout(fetchWithRetry, 2000);
        } else {
          setHasMore(false);
          setDisableNavigation(false);
          setIsLoadingMore(false);
        }
      }
    };

    await fetchWithRetry();
  };

  const handleNextPage = () => {
    if (hasMore) {
      loadEmails(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      loadEmails(page - 1);
    }
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleUpdate = async () => {
    setLoadingUpdate(true);
    try {
      await updateEmail(emails[0].search_history_result.id, emailContent);
      setEditing(false);
    } catch (error) {
      console.error('Error updating email:', error);
    } finally {
      setLoadingUpdate(false);
    }
  };

  const handleDelete = (emailId) => {
    setDeleteEmailId(emailId);
    setShowDeleteModal(true);
  };

  const handleDeleteEmail = async () => {
    setLoadingUpdate(true);
    try {
      await deleteEmail(deleteEmailId);
      setEmails([]);
      loadEmails(page);
    } catch (error) {
      console.error('Error deleting email:', error);
    } finally {
      setLoadingUpdate(false);
      setShowDeleteModal(false);
    }
  };

  const handleApprove = async () => {
    if (!emails[0]) return;

    setLoadingApprove(true);
    try {
      await approveEmail(emails[0].search_history_result.id);
      toast.success('Email added to queue');
      loadEmails(page);
    } catch (error) {
      console.error('Error approving email:', error);
    } finally {
      setLoadingApprove(false);
    }
  };

  const handleRefetch = async () => {
    if (!emails[0]) return;

    setLoadingRefetch(true);
    const single_email = emails[0];
    const payload = {
      employment_history: single_email['search_history_result']['employment_history'],
      first_name: single_email['search_history_result']['first_name'],
      headline: single_email['scrapped_data']['linkedin_data']['headline'],
      id: single_email['search_history_result']['id'],
      last_name: single_email['search_history_result']['last_name'],
      linkedin_url: single_email['search_history_result']['linkedin_url'],
      name: single_email['search_history_result']['full_name'],
      organization: single_email['search_history_result']['organization'],
      title: single_email['search_history_result']['title'],
      email: 'temp@temp.com',
      inputText: inputText,
      organization_name: single_email['scrapped_data']['current_company_linked_data']['companyName'],
    };

    try {
      await refetchEmail(payload['id'], payload);
      loadEmails(page);
      setLoadingRefetch(false);
    } catch (error) {
      console.error('Error fetching email:', error);
    } finally {
      setLoadingApprove(false);
    }
  };

  const handleRefetchPopUp = () => {
    setShowRewritePopUp(true);
  };

  const handleRewrite = () => {
    handleRefetch();
    setShowRewritePopUp(false);
    setInputText('');
  };

  return email ? (
    <div className="pending-email-container-wrapper">
      <div className="pending-email-container">
        <EmailContent
          email={email}
          emailContent={emailContent}
          setEmailContent={setEmailContent}
          emailSubject={emailSubject}
          editing={editing}
          handleEdit={handleEdit}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
          handleApprove={handleApprove}
          handleRefetchPopUp={handleRewrite}
          loadingUpdate={loadingUpdate}
          loadingApprove={loadingApprove}
          loadingRefetch={loadingRefetch}
        />
        <InfoContainer email={email} currentTab={currentTab} setCurrentTab={setCurrentTab} />
      </div>
      <div className="pagination-controls">
        <Button
          onClick={handlePreviousPage}
          disabled={disableNavigation || page === 1 || isLoadingMore || loadingRefetch}
        >
          Previous
        </Button>
        <Button
          onClick={handleNextPage}
          disabled={disableNavigation || !hasMore || isLoadingMore || loadingRefetch}
        >
          Next
        </Button>
      </div>
      <DeleteEmailModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        handleDeleteEmail={handleDeleteEmail}
      />
      <RewriteEmailModal
        show={showRewritePopUp}
        onHide={() => setShowRewritePopUp(false)}
        inputText={inputText}
        setInputText={setInputText}
        handleRewrite={handleRewrite}
      />
    </div>
  ) : null;
};

// Sub-components defined within the same file

const EmailContent = ({
  email,
  emailContent,
  setEmailContent,
  emailSubject,
  editing,
  handleEdit,
  handleUpdate,
  handleDelete,
  handleApprove,
  handleRefetchPopUp,
  loadingUpdate,
  loadingApprove,
  loadingRefetch,
}) => {
  return (
    <div className="email-content-wrapper">
      <div className="email-content-upper">
        <div className="generate-email-banner">
          <span style={{ marginRight: '10px' }}>
            <img src={generateMailMagic} alt="Generate Email" />
          </span>
        </div>
        <div className="email-header">
          <div className="to-user">To</div>
          <div className="user-email-info">
            <img
              src={
                email.scrapped_data.linkedin_data?.profilePic ||
                email.search_history_result.photo_url ||
                DefaultProfile
              }
              alt="Person"
              className="person-image"
              onError={(e) => {
                e.target.src = DefaultProfile;
              }}
            />
            <span className="person-name">{email.search_history_result.full_name}</span>
          </div>
        </div>
        <div className="email-subject">{`Subject: ${emailSubject}`}</div>
        <textarea
          rows={16}
          value={emailContent}
          readOnly={!editing}
          onChange={(e) => setEmailContent(e.target.value)}
          className={`email-content ${!editing ? 'read-only' : ''}`}
        />
      </div>

      <div className="all-btn-groups">
        <div
          className="btn-right-group btn btn-delete"
          onClick={() => handleDelete(email.search_history_result.id)}
        >
          <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faTrashCan} title="Delete Item" />
          Delete
        </div>

        {editing ? (
          <div onClick={handleUpdate} className="btn btn-save">
            {loadingUpdate ? (
              <FontAwesomeIcon className="spinner" icon={faRotate} title="Saving..." />
            ) : (
              <FontAwesomeIcon icon={faSave} title="Save Changes" />
            )}
            {loadingUpdate ? 'Saving' : 'Save'}
          </div>
        ) : (
          <div className="btn btn-edit" onClick={handleEdit}>
            <FontAwesomeIcon icon={faEdit} title="Edit" />
            <span>Edit</span>
          </div>
        )}
        <div className="btn btn-refetch" onClick={handleRefetchPopUp}>
          <FontAwesomeIcon icon={faRotate} title="Refetch email" spin={loadingRefetch} />
          Rewrite
        </div>

        <div className="btn btn-approve" onClick={handleApprove}>
          {loadingApprove ? (
            <FontAwesomeIcon className="spinner" icon={faRotate} title="Approving..." />
          ) : (
            <FontAwesomeIcon icon={faCircleCheck} title="Approve" />
          )}
          Approve
        </div>
      </div>
    </div>
  );
};

const InfoContainer = ({ email, currentTab, setCurrentTab }) => {
  const handleTabSwitch = () => {
    setCurrentTab(currentTab === 'user' ? 'company' : 'user');
  };

  return (
    <div className="right-container">
      <div className="info-header">
        <div className="info-header-left"></div>
        {currentTab === 'user' &&
          email.scrapped_data?.current_company_linkedin_url &&
          !email.scrapped_data?.current_company_linkedin_url.startsWith(
            'https://www.linkedin.com/search/results/all/?keywords'
          ) && (
            <div className="info-header-right" onClick={handleTabSwitch}>
              Switch to company
            </div>
          )}
        {currentTab === 'company' && (
          <div className="info-header-right" onClick={handleTabSwitch}>
            Switch to user
          </div>
        )}
      </div>
      {currentTab === 'user' ? (
        <UserInfo email={email} />
      ) : (
        <CompanyInfo email={email} />
      )}
    </div>
  );
};

const UserInfo = ({ email }) => {
  const [userActiveTab, setUserActiveTab] = useState('general');
  const [showModal, setShowModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState('');

  const handleUserTabClick = (tabName) => {
    setUserActiveTab(tabName);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleReadMore = (post) => {
    setSelectedPost(post['post_text']);
    setShowModal(true);
  };

  return (
    <div className="user-info-container">
      <div className="comp-name-logo-location-container">
        <div className="comp-name-white-bg">
          <div className="comp-logo">
            <img
              className="company-image"
              src={
                email.scrapped_data.linkedin_data?.profilePic ||
                email.search_history_result.photo_url ||
                DefaultProfile
              }
              alt="User"
              onError={(e) => {
                e.target.src = DefaultProfile;
              }}
            />
          </div>
          <div className="comp-name-location">
            <div className="comp-name">{email.search_history_result.full_name}</div>
            <div className="comp-industry">
              {email.scrapped_data?.linkedin_data?.headline ||
                email.search_history_result?.employment_history[0]?.title ||
                ''}
              <span className="comp-name-user-container">
                {email.scrapped_data?.current_company_linked_data &&
                  email.scrapped_data.current_company_linked_data.websiteUrl ? (
                  <a
                    href={
                      email.scrapped_data?.current_company_linked_data.websiteUrl.startsWith('http')
                        ? email.scrapped_data.current_company_linked_data.websiteUrl
                        : `https://${email.scrapped_data.current_company_linked_data.websiteUrl}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {email.scrapped_data.current_company_linked_data.companyName}
                  </a>
                ) : (
                  email.scrapped_data?.current_company_linked_data?.companyName ||
                  email.scrapped_data.linkedin_data?.experiences[0].company_name
                    .split('·')[0]
                    .trim()
                )}
              </span>
            </div>

            <div className="comp-location">
              {email.scrapped_data.linkedin_data?.addressWithoutCountry
                ? email.scrapped_data.linkedin_data?.addressWithoutCountry
                : ''}
            </div>
          </div>
        </div>
        <div className="comp-details-container">
          <span className="comp-details-span">
            <a
              href={email.search_history_result.linkedin_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedinSolid} alt="LinkedIn" className="company-icon" />
            </a>
          </span>
        </div>
      </div>

      <div className="tabs-container">
        <div className="tabs">
          <button
            className={userActiveTab === 'general' ? 'tab active' : 'tab'}
            onClick={() => handleUserTabClick('general')}
          >
            General
          </button>
          <button
            className={userActiveTab === 'experience' ? 'tab active' : 'tab'}
            onClick={() => handleUserTabClick('experience')}
          >
            Experiences
            <span className="badge">
              {email.scrapped_data.linkedin_data?.experiences.length}
            </span>
          </button>
          <button
            className={userActiveTab === 'skills' ? 'tab active' : 'tab'}
            onClick={() => handleUserTabClick('skills')}
          >
            Skills
          </button>
          {email.scrapped_data.linkedin_posts &&
            email.scrapped_data.linkedin_posts.length > 0 && (
              <button
                className={userActiveTab === 'posts' ? 'tab active' : 'tab'}
                onClick={() => handleUserTabClick('posts')}
              >
                Posts
              </button>
            )}
        </div>

        <div className="tab-content">
          {userActiveTab === 'general' && (
            <div>
              <span className="about-header">About:</span>
              <p className="scrollable-about">
                {email.scrapped_data?.person_summary &&
                  email.scrapped_data.person_summary.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
            </div>
          )}
          {userActiveTab === 'experience' && (
            <div className="scrollable-about">
              {email.scrapped_data.linkedin_data?.experiences &&
                email.scrapped_data.linkedin_data?.experiences.length > 0 ? (
                <ul className="experience-list">
                  {email.scrapped_data.linkedin_data.experiences.map((post, index) => (
                    <li key={index} className="experience-item">
                      <div className="experience-container">
                        <div className="experience-container-name">
                          <img
                            className="experience-company-logo"
                            src={post?.logo || DefaultCompLogo}
                            alt=""
                            onError={(e) => {
                              e.target.src = DefaultCompLogo;
                            }}
                          />
                          <div>
                            <div className="employment-title">
                              {post.employment_breakdown === undefined ? post.title : null}
                            </div>
                            <div className="company-name">
                              <a
                                href={post.company_linkedin_url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {post.company_name.split('·')[0].trim()}
                              </a>
                            </div>
                            {post.employment_breakdown !== undefined ? (
                              <div className="company-name-breakdown">
                                <span>{post.caption}</span>
                                <span>{post.total_employment_length}</span>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="experience-details">
                          {post.employment_breakdown === undefined ? (
                            <div className="employment-duration">
                              {post.location}{' '}
                              <span className="employment-duration-company">
                                {post.total_employment_length}{' '}
                              </span>
                            </div>
                          ) : (
                            <div className="employment-breakdown">
                              <ul>
                                {post?.employment_breakdown.map((emp_post, emp_idx) => (
                                  <li key={emp_idx}>
                                    <p className="employment-breakdown-title">{emp_post.title}</p>
                                    <p className="employment-breakdown-timeline">
                                      {emp_post.caption}
                                    </p>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No experience data available</p>
              )}
            </div>
          )}
          {userActiveTab === 'skills' && (
            <div className="scrollable-about">
              {email.scrapped_data?.linkedin_data?.skills &&
                email.scrapped_data?.linkedin_data?.skills.map((item, index) => (
                  <div key={index} className="pill-box">
                    {item.title}
                  </div>
                ))}
            </div>
          )}
          {userActiveTab === 'posts' && (
            <div className="scrollable-about">
              {email.scrapped_data.linkedin_posts &&
                email.scrapped_data.linkedin_posts.length > 0 ? (
                <ul className="post-list">
                  {email.scrapped_data.linkedin_posts.map((post, index) => (
                    <li key={index} className="post-item">
                      <div>
                        <img
                          src={
                            email.scrapped_data.linkedin_data?.profilePic ||
                            email.search_history_result.photo_url ||
                            DefaultProfile
                          }
                          alt="Person"
                          className="person-image-posts"
                          onError={(e) => {
                            e.target.src = DefaultProfile;
                          }}
                        />
                        <span className="person-name-posts">
                          {email.search_history_result.full_name}
                        </span>
                      </div>
                      <p className="posts-container">
                        {post['post_text'].length > 150 ? (
                          <>
                            {post['post_text'].slice(0, 150)}...
                            <a
                              href={post['post_link']}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: '#007bff',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                              }}
                              className="view-post"
                            >
                              View Post
                            </a>
                          </>
                        ) : (
                          <>
                            {post['post_text']}
                            <a
                              href={post['post_link']}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: '#007bff',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                marginLeft: '10px',
                              }}
                              className="view-post"
                            >
                              View Post
                            </a>
                          </>
                        )}
                      </p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No posts</p>
              )}
              <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>{selectedPost}</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const CompanyInfo = ({ email }) => {
  const [activeTab, setActiveTab] = useState('general');
  const [showModal, setShowModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState('');
  const [blogs, setBlogs] = useState([]);
  const [recentNews, setRecentNews] = useState([]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleReadMoreCompanyPost = (post) => {
    setSelectedPost(post['postText']);
    setShowModal(true);
  };

  useEffect(() => {
    // Fetch blogs and recent news if necessary
  }, [email]);

  return (
    <div className="comp-info-container">
      <div className="comp-name-logo-location-container">
        <div className="comp-info-name-white-bg">
          <div className="comp-logo">
            <img
              className="company-image"
              src={
                email?.scrapped_data?.linkedin_data?.experiences[0]?.logo || DefaultCompLogo
              }
              alt="Comp Logo"
              onError={(e) => {
                e.target.src = DefaultCompLogo;
              }}
            />
          </div>
          <div className="comp-name-location">
            <div className="comp-name">
              {email.scrapped_data.current_company_linked_data?.companyName}
            </div>
            <div className="comp-industry">
              {email.scrapped_data.current_company_linked_data?.tagline}
            </div>
            <div className="comp-industry">
              {email.scrapped_data.current_company_linked_data?.industry}
            </div>
            <div className="comp-industry">
              {email.scrapped_data.current_company_linked_data.employeeCountRange === undefined
                ? email.scrapped_data.current_company_linked_data.employeeCount
                : `${email.scrapped_data.current_company_linked_data?.employeeCountRange.start} - ${email.scrapped_data.current_company_linked_data?.employeeCountRange.end}`}{' '}
              employees
            </div>
            <div className="comp-location">
              {email.scrapped_data.current_company_linked_data?.headquarter?.city
                ? `${email.scrapped_data.current_company_linked_data.headquarter.city}${email.scrapped_data.current_company_linked_data?.headquarter?.geographicArea
                  ? ', ' + email.scrapped_data.current_company_linked_data.headquarter.geographicArea
                  : ''
                }`
                : email.scrapped_data.current_company_linked_data?.headquarter?.geographicArea ||
                ''}
            </div>
          </div>
        </div>
        <div className="comp-details-container">
          <span className="comp-details-span">
            <a
              href={email.scrapped_data.current_company_linked_data?.websiteUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="comp-details-icon" src={externalLink} alt="Website" />
            </a>
          </span>
          <span className="comp-details-span">
            <a
              href={email.scrapped_data.current_company_linked_data?.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedinSolid} alt="LinkedIn" className="company-icon" />
            </a>
          </span>
        </div>
      </div>

      <div className="tabs-container">
        <div className="tabs">
          <button
            className={activeTab === 'general' ? 'tab active' : 'tab'}
            onClick={() => handleTabClick('general')}
          >
            General
          </button>
          {blogs && blogs.length > 0 && (
            <button
              className={activeTab === 'blogs' ? 'tab active' : 'tab'}
              onClick={() => handleTabClick('blogs')}
            >
              Blogs
            </button>
          )}
          {email.scrapped_data.company_linkedin_posts &&
            email.scrapped_data.company_linkedin_posts.length > 0 && (
              <button
                className={activeTab === 'comp_posts' ? 'tab active' : 'tab'}
                onClick={() => handleTabClick('comp_posts')}
              >
                Posts
              </button>
            )}
        </div>

        <div className="tab-content">
          {activeTab === 'general' && (
            <div>
              <span className="about-header">About:</span>
              <p className="scrollable-about">
                {email.scrapped_data.company_summary ? (
                  email.scrapped_data.company_summary
                ) : (
                  email.scrapped_data?.current_company_linked_data?.description &&
                  email.scrapped_data.current_company_linked_data.description
                    .split('\n')
                    .map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))
                )}
              </p>
            </div>
          )}
          {activeTab === 'blogs' && (
            <div className="scrollable-about">
              {blogs && blogs.length > 0 ? (
                <ul className="post-list">
                  {blogs.map((news, index) => (
                    <li key={index} className="post-item">
                      <p>
                        <a href={news} target="_blank" rel="noopener noreferrer">
                          {news}
                        </a>
                      </p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No Blogs Found</p>
              )}
            </div>
          )}
          {activeTab === 'comp_posts' && (
            <div className="scrollable-about">
              {email.scrapped_data.company_linkedin_posts &&
                email.scrapped_data?.company_linkedin_posts.length > 0 ? (
                <ul className="post-list">
                  {email.scrapped_data.company_linkedin_posts
                    .filter((post) => post['postText'] && post['postText'].trim().length > 0)
                    .map((post, index) => (
                      <li key={index} className="post-item">
                        <div>
                          <img
                            src={email.search_history_result.organization.logo_url || DefaultProfile}
                            alt="Company"
                            className="person-image-posts"
                            onError={(e) => {
                              e.target.src = DefaultProfile;
                            }}
                          />
                          <span className="person-name-posts">
                            {email.scrapped_data.current_company_linked_data?.companyName}
                          </span>
                        </div>
                        <p className="posts-container">
                          {post['postText'].length > 150 ? (
                            <>
                              {post['postText'].slice(0, 150)}...
                              <a
                                href={post['postLink']}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: '#007bff',
                                  cursor: 'pointer',
                                  textDecoration: 'underline',
                                }}
                                className="view-post"
                              >
                                View Post
                              </a>
                            </>
                          ) : (
                            <>
                              {post['postText']}
                              <a
                                href={post['postLink']}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: '#007bff',
                                  cursor: 'pointer',
                                  textDecoration: 'underline',
                                  marginLeft: '10px',
                                }}
                                className="view-post"
                              >
                                View Post
                              </a>
                            </>
                          )}
                        </p>
                      </li>
                    ))}
                </ul>
              ) : (
                <p>No Recent Posts</p>
              )}
              <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>{selectedPost}</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const DeleteEmailModal = ({ show, onHide, handleDeleteEmail }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this email?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDeleteEmail}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const RewriteEmailModal = ({ show, onHide, inputText, setInputText, handleRewrite }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Rewrite Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="rewriteText">
          <Form.Label>Enter instructions:</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder="Enter instruction to update the email"
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleRewrite}>
          Rewrite
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailManager;
