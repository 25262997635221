// @ts-nocheck
// Tabs.jsx

import React from "react";
import styled from "styled-components";

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TabList = styled.div`
  display: flex;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 24px;
`;

const TabTrigger = styled.button`
  background: none;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: color 0.2s, border-bottom 0.2s;
  border-bottom: ${({ active }) =>
    active ? "2px solid #3b82f6" : "2px solid transparent"};
  color: ${({ active }) => (active ? "#3b82f6" : "inherit")};

  &:hover {
    color: #3b82f6;
  }
`;

const Tabs = ({ activeTab, setActiveTab, tabs }) => {
  return (
    <TabsContainer>
      <TabList>
        {tabs.map((tab) => (
          <TabTrigger
            key={tab.key}
            active={activeTab === tab.key}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.icon}
            {tab.label}
          </TabTrigger>
        ))}
      </TabList>
    </TabsContainer>
  );
};

export default Tabs;
