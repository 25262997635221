// @ts-nocheck
// EmailCampaignManager.jsx

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { Mail, Calendar, Edit, Save, Play, Pause, Square } from "lucide-react";

import Header from "./Header";
import Sidebar from "./Sidebar";
import Tabs from "./Tabs";
import EmailSequence from "./EmailSequence";
import Schedule from "./Schedule";
import Settings from "./Settings";
import Button from "./Button";
import Spinner from "./Spinner";

const Container = styled.div`
  background-color: #f9fafb;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
`;

const PageContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 8px 16px;
  flex: 1;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 32px;
  min-height: 100vh;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  background-color: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const CardHeader = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e7eb;

  &.main-header {
    justify-content: space-between;
  }

  h2,
  h1 {
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0;
  }
`;

const CardTitle = styled.h1`
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
`;

const CardContent = styled.div`
  padding: 16px;
  flex: 1;
  overflow-y: auto;
`;

const NoSelection = styled.div`
  text-align: center;
  color: #6b7280;
  padding: 48px 0;
`;

const Error = styled.div`
  color: #ef4444;
  padding: 0.75rem;
  margin-bottom: 1rem;
  background-color: #fee2e2;
  border-radius: 0.375rem;
`;

const EmailCampaignManager = () => {
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [loadingCampaigns, setLoadingCampaigns] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("sequence");
  const [sequences, setSequences] = useState([]);
  const [loadingSequences, setLoadingSequences] = useState(false);
  const [editingSequence, setEditingSequence] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [campaignStatus, setCampaignStatus] = useState(null);
  const [updatingStatus, setUpdatingStatus] = useState(false);

  // Extract campaign loading logic into a separate function
  const loadCampaigns = async () => {
    try {
      setLoadingCampaigns(true);
      const user = JSON.parse(localStorage.getItem("actio_user"));
      const authToken = `Bearer ${user.id}@@@${user.email}`;
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/campaign_list`,
        {
          headers: { Authorization: authToken },
        }
      );
      setCampaigns(response.data);
    } catch (err) {
      setError("Failed to load campaigns");
      console.error(err);
    } finally {
      setLoadingCampaigns(false);
    }
  };

  // Use the extracted function in useEffect
  useEffect(() => {
    loadCampaigns();
  }, []);

  // Fetch sequences when a campaign is selected
  useEffect(() => {
    const fetchSequences = async () => {
      if (!selectedCampaign) return;
      try {
        setLoadingSequences(true);
        const user = JSON.parse(localStorage.getItem("actio_user"));
        const authToken = `Bearer ${user.id}@@@${user.email}`;
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/campaign/${selectedCampaign}/sequences`,
          { headers: { Authorization: authToken } }
        );
        const transformedData = response.data.data.map((sequence) => ({
          ...sequence,
          clientId: `seq_${Date.now()}_${Math.random()
            .toString(36)
            .substr(2, 9)}`,
          seq_number: sequence.seq_number,
          delay_in_days: sequence.seq_delay_details.delayInDays,
          subject:
            sequence?.sequence_variants?.[0]?.subject || sequence.subject,
          email_body:
            sequence?.sequence_variants?.[0]?.email_body || sequence.email_body,
        }));
        setSequences(transformedData);
      } catch (err) {
        setError("Failed to load sequences");
        console.error(err);
      } finally {
        setLoadingSequences(false);
      }
    };
    fetchSequences();
  }, [selectedCampaign]);

  const handleAddSequence = () => {
    const newSequence = {
      clientId: `seq_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
      seq_number: sequences.length + 1,
      delay_in_days: 1,
      subject: "",
      email_body: "",
      isNew: true,
    };
    setSequences([...sequences, newSequence]);
    setEditingSequence(newSequence.clientId);
  };

  const handleSequenceChange = (sequenceId, field, value) => {
    setSequences(
      sequences.map((seq) =>
        seq.clientId === sequenceId ? { ...seq, [field]: value } : seq
      )
    );
  };

  const handleDeleteSequence = async (sequenceId) => {
    try {
      setSequences(sequences.filter((seq) => seq.clientId !== sequenceId));
    } catch (err) {
      setError("Failed to delete sequence");
      console.error(err);
    }
  };

  const handleSaveSequences = async () => {
    try {
      setIsSaving(true);
      const user = JSON.parse(localStorage.getItem("actio_user"));
      const authToken = `Bearer ${user.id}@@@${user.email}`;

      const newSequences = sequences.map((seq) => ({
        seq_number: seq.seq_number,
        seq_delay_details: { delay_in_days: Number(seq.delay_in_days || 0) },
        seq_variants: [{ subject: seq.subject, email_body: seq.email_body }],
      }));

      if (newSequences.length > 0) {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/campaign/${selectedCampaign}/sequences`,
          { sequences: newSequences },
          { headers: { Authorization: authToken } }
        );
      }

      // Mark all sequences as not new
      setSequences(sequences.map((seq) => ({ ...seq, isNew: false })));
      setEditingSequence(null);
    } catch (err) {
      setError("Failed to save sequences");
      console.error(err);
    } finally {
      setIsSaving(false);
    }
  };

  // Update handleCampaignStatusUpdate to use loadCampaigns
  const handleCampaignStatusUpdate = async (newStatus) => {
    try {
      setUpdatingStatus(true);
      const user = JSON.parse(localStorage.getItem("actio_user"));
      const authToken = `Bearer ${user.id}@@@${user.email}`;

      await axios.patch(
        `${process.env.REACT_APP_API_URL}/campaign/${selectedCampaign}/status`,
        { status: newStatus },
        { headers: { Authorization: authToken } }
      );

      setCampaignStatus(newStatus);
      await loadCampaigns(); // Reload campaigns after status update
    } catch (err) {
      setError("Failed to update campaign status");
      console.error(err);
    } finally {
      setUpdatingStatus(false);
    }
  };

  const tabs = [
    { key: "sequence", label: "Email Sequence", icon: <Mail size={16} /> },
    { key: "schedule", label: "Schedule", icon: <Calendar size={16} /> },
    { key: "settings", label: "Settings", icon: <Edit size={16} /> },
  ];

  const selectedCampaignData = campaigns.find((c) => c.id === selectedCampaign);

  return (
    <Container>
      <Header />
      <PageContent>
        {error && <Error>{error}</Error>}
        <Grid>
          {/* Sidebar */}
          <Sidebar
            campaigns={campaigns}
            loadingCampaigns={loadingCampaigns}
            selectedCampaign={selectedCampaign}
            setSelectedCampaign={setSelectedCampaign}
          />

          {/* Main Content */}
          <div style={{ width: "850px" }}>
            <Card>
              <CardHeader className="main-header">
                <CardTitle>
                  {selectedCampaign
                    ? campaigns.find((c) => c.id === selectedCampaign)?.name
                    : "Select a Campaign"}
                </CardTitle>
                {selectedCampaign && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <Button
                      variant="primary"
                      fullWidth={false}
                      onClick={() =>
                        handleCampaignStatusUpdate(
                          campaignStatus === "START" ? "PAUSED" : "START"
                        )
                      }
                      disabled={updatingStatus}
                    >
                      {campaignStatus === "START" ? (
                        <>
                          <Pause size={16} /> Pause Campaign
                        </>
                      ) : (
                        <>
                          <Play size={16} />{" "}
                          {campaignStatus === "PAUSED" ? "Resume" : "Launch"}{" "}
                          Campaign
                        </>
                      )}
                    </Button>
                    {campaignStatus === "START" ||
                    campaignStatus === "PAUSED" ? (
                      <Button
                        variant="danger"
                        fullWidth={false}
                        onClick={() => handleCampaignStatusUpdate("STOPPED")}
                        disabled={updatingStatus}
                      >
                        <Square size={16} /> Stop Campaign
                      </Button>
                    ) : null}
                  </div>
                )}
              </CardHeader>
              <CardContent>
                {selectedCampaign ? (
                  <>
                    <Tabs
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      tabs={tabs}
                    />
                    {/* Tab Content */}
                    {activeTab === "sequence" && (
                      <EmailSequence
                        sequences={sequences}
                        loadingSequences={loadingSequences}
                        handleAddSequence={handleAddSequence}
                        handleSequenceChange={handleSequenceChange}
                        handleDeleteSequence={handleDeleteSequence}
                        editingSequence={editingSequence}
                        setEditingSequence={setEditingSequence}
                        handleSaveSequences={handleSaveSequences}
                        isSaving={isSaving}
                      />
                    )}
                    {activeTab === "schedule" && (
                      <Schedule
                        campaignId={selectedCampaign}
                        campaignData={selectedCampaignData}
                        reloadCampaigns={loadCampaigns}
                      />
                    )}
                    {activeTab === "settings" && (
                      <Settings
                        campaignId={selectedCampaign}
                        campaignName={
                          campaigns.find((c) => c.id === selectedCampaign)?.name
                        }
                        reloadCampaigns={loadCampaigns}
                      />
                    )}
                  </>
                ) : (
                  <NoSelection>
                    Select a campaign from the list to get started
                  </NoSelection>
                )}
              </CardContent>
            </Card>
          </div>
        </Grid>
      </PageContent>
    </Container>
  );
};

export default EmailCampaignManager;
