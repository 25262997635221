import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import styles from './Header.module.css'; // Importing the CSS module

const HeaderMailBox = () => {
  return (
    <div className={styles.headerContainer}>
      <Container fluid>
        <Row className="align-items-center justify-content-between">
          <Col md={8}>
            <h2 className={styles.headerTitle}>Mailbox</h2>
            <p className={styles.headerSubtitle}>
              Track your emails here
            </p>
          </Col>
          {/* <Col md="auto">
            <Button className={styles.addLeadsBtn}>
              + Add new leads
            </Button>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default HeaderMailBox;
