// @ts-nocheck
// Header.jsx

import React from "react";
import styled from "styled-components";

const HeaderContainer = styled.div`
  max-width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem 2.5rem;
  background-color: white;
  z-index: 10;
  border-bottom: 1px solid rgba(229, 231, 235, 0.8);
  position: sticky;
  top: 0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(8px);
  transition: all 0.3s ease;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const Description = styled.p`
  color: #6b7280;
`;

const Header = () => {
  return (
    <HeaderContainer>
      <Title>Email Campaign Manager</Title>
      <Description>
        Create and manage email campaigns to engage with your audience
        effectively. Customize sequences, schedules, and settings for each
        campaign.
      </Description>
    </HeaderContainer>
  );
};

export default Header;
