import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Container, Col, ListGroup, Form, Button, Modal, Accordion, Card } from 'react-bootstrap';
import axios from 'axios';
import './CampaignInbox.css';
import { ToastContainer, toast } from 'react-toastify';
import HeaderMailBox from '../components/Headers/HeadersMailBox';
import DefaultProfile from "../assets/images/default_profile.svg";
import MailBoxAnimatedGif from "../assets/images/mail-box.gif"
import Lottie from "lottie-react";
import mailBoxAnimation from "../assets/images/Animation - 1726809812574.json"
import mainLoader from "../assets/images/AnimationLoader.json"
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { FaCheckCircle, FaPaperPlane, FaReply } from 'react-icons/fa';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


export default function CampaignInbox() {
    const { campaignId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [leads, setLeads] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [hasCC, setHasCC] = useState(false);
    const [ccMail, setCCMail] = useState("")
    const [messages, setMessages] = useState([]);
    const [selectedLead, setSelectedLead] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [msgType, setMsgType] = useState("inbox");  // "inbox" or "sent"
    const [reply, setReply] = useState('');
    const [masterInboxLoader, setMasterInboxLoader] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [addSignature, setAddSignature] = useState(false);
    const [userSignature, setUserSignature] = useState(null)
    const [parentUser, setParentUser] = useState()
    const limit = 10;

    const [expanded, setExpanded] = useState(null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('actio_user'));
        setParentUser(user)

       
    }, []); 

    const formatEmailBody = (body) => {
        if (/<[a-z][\s\S]*>/i.test(body)) {
          // If the body contains HTML tags, render as HTML
          return body;
        } else {
          // Otherwise, replace newlines with <br>
          return body.replace(/\n/g, '<br/>');
        }
      };

    const handleSignatureChange = (e) => {
        setAddSignature(e.target.checked);
      };

    const toggleCC = () =>{
        setHasCC(!hasCC);
    }
    const handleCCChange = (e) => {
        setCCMail(e.target.value)
    }

    const toggleExpand = (index) => {
        setExpanded(expanded === index ? null : index);
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('actio_user'));
        axios.get(process.env.REACT_APP_API_URL + '/user/settings', {
            headers: {
                'Authorization': `Bearer ${user.id}@@@${user.email}`,
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            console.log(response.data.data);
            const settingData = response.data.data
            setUserSignature(settingData.signature)
        })
        .catch((error) => {
            console.log(error);
        });
    }, []);

    // const renderEmailPreview = (email_body) => {
    //     // Strip HTML tags for preview
    //     const parser = new DOMParser();
    //     const doc = parser.parseFromString(email_body, 'text/html');
    //     return doc.body.textContent.slice(0, 100) + '...';
    // };

    const renderEmailPreview = (email_body) => {
        // Strip HTML tags for preview and handle new lines
        const parser = new DOMParser();
        const doc = parser.parseFromString(email_body, 'text/html');
        
        // Replace <br> tags with newlines
        doc.body.querySelectorAll('br').forEach((br) => br.replaceWith('\n'));
    
        // Optionally, replace block-level elements with newlines
        ['p', 'div', 'li'].forEach(tag => {
            doc.body.querySelectorAll(tag).forEach((el) => {
                el.outerHTML = `\n${el.innerHTML}\n`;
            });
        });
    
        // Get text content and trim for preview
        const textContent = doc.body.textContent || '';
        
        // Return the first 100 characters with a trailing ellipsis
        return textContent.trim().slice(0, 100) + '...';
    };
    


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const emailLeadMapId = params.get('emailLeadMapId');
        const emailLeadId = params.get('emailLeadId');

        // Initial load
        // loadLeadsWithOffset(0);
        // loadLeadsWithOffset(limit);

        if (emailLeadMapId && emailLeadId) {
            fetchMessages(emailLeadMapId, emailLeadId);
        }
    }, [location.search, msgType]); // msgType dependency to trigger reload on toggle

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const emailLeadMapId = params.get('emailLeadMapId');
        const emailLeadId = params.get('emailLeadId');

        // Initial load
        loadLeadsWithOffset(0);
        loadLeadsWithOffset(limit);

        if (emailLeadMapId && emailLeadId) {
            fetchMessages(emailLeadMapId, emailLeadId);
        }
    }, []); // msgType dependency to trigger reload on toggle

    const loadLeadsWithOffset = (initialOffset, apiType = "inbox") => {
        if (isLoading) return;

        setIsLoading(true);
        const user = JSON.parse(localStorage.getItem('actio_user'));
        const apiUrl = apiType === "inbox"
            ? `${process.env.REACT_APP_API_URL}/campaign/mailbox/campaign/${campaignId}`
            : `${process.env.REACT_APP_API_URL}/campaign/mailbox/sent/campaign/${campaignId}`;

        axios.get(apiUrl, {
            params: { offset: initialOffset, limit },
            headers: { Authorization: `Bearer ${user.id}@@@${user.email}` },
        })
        .then(response => {
            setLeads(prevLeads => [...prevLeads, ...response.data.data]);
            if (initialOffset === limit) {
                setOffset(prevOffset => prevOffset + limit);
            }
        })
        .catch(error => {
            console.error('Error fetching leads:', error);
        })
        .finally(() => {
            setIsLoading(false);
        });
    };

    const formatDate = (date) => {
        const currentDate = new Date();
        const inputDate = new Date(date);
    
        const isSameDay = currentDate.toDateString() === inputDate.toDateString();
        const isSameWeek = currentDate.getTime() - inputDate.getTime() < 7 * 24 * 60 * 60 * 1000 && inputDate.getTime() < currentDate.getTime();
    
        if (isSameDay) {
            return `Today ${inputDate.toLocaleTimeString(navigator.language, { hour: 'numeric', minute: '2-digit', hour12: true })}`;
        } else if (isSameWeek) {
            return `${inputDate.toLocaleDateString(navigator.language, { weekday: 'short' })} ${inputDate.toLocaleTimeString(navigator.language, { hour: 'numeric', minute: '2-digit', hour12: true })}`;
        } else {
            return new Intl.DateTimeFormat(navigator.language, {
                year: '2-digit', 
                month: 'short',  
                day: '2-digit',
            }).format(inputDate);
        }
    };

    const loadMoreLeads = () => {
        if (isLoading) return;

        setIsLoading(true);
        const user = JSON.parse(localStorage.getItem('actio_user'));
        const apiUrl = msgType === "inbox"
            ? `${process.env.REACT_APP_API_URL}/campaign/mailbox/campaign/${campaignId}`
            : `${process.env.REACT_APP_API_URL}/campaign/mailbox/sent/campaign/${campaignId}`;

        axios.get(apiUrl, {
            params: { offset, limit },
            headers: { Authorization: `Bearer ${user.id}@@@${user.email}` },
        })
        .then(response => {
            setLeads(prevLeads => [...prevLeads, ...response.data.data]);
            setOffset(prevOffset => prevOffset + limit);
        })
        .catch(error => {
            console.error('Error fetching leads:', error);
        })
        .finally(() => {
            setIsLoading(false);
        });
    };

    const handleScroll = (event) => {
        const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
        if (bottom && !isLoading) {
            loadMoreLeads();
        }
    };

    const fetchMessages = (emailLeadMapId, emailLeadId, lead) => {
        setShowModal(false)
        setMasterInboxLoader(true)
        console.log("lead", lead)
        const user = JSON.parse(localStorage.getItem('actio_user'));
        axios.post(`${process.env.REACT_APP_API_URL}/mailbox/campaign/${campaignId}/lead/emails`, {
            email_lead_map_id: emailLeadMapId,
            email_lead_id: emailLeadId,
        }, {
            headers: { Authorization: `Bearer ${user.id}@@@${user.email}` },
        })
        .then(response => {
            setSelectedUser({
                "photot_url": lead.user_data?.photo_url,
                "first_name": lead.first_name,
                "last_name": lead.last_name,
                "email": lead.email,
                "last_sent_time": lead.last_sent_time
            });
            setMessages(response.data.data);
            setSelectedLead({ emailLeadMapId, emailLeadId });
            // navigate(`?emailLeadMapId=${emailLeadMapId}&emailLeadId=${emailLeadId}`);
            setMasterInboxLoader(false)
        })
        .catch(error => {
            console.error('Error fetching messages:', error);
        });
    };

    const handleReplyChange = (value) => {
        setReply(value);
    };

    function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const handleSendReply = () => {
        if (reply.trim() === '' || !selectedLead) 
        {
            toast.warning("No email content found")
            return};
        if (reply.length === 0){
            toast.warning("No email content found")
            return
        }

        if (hasCC && !isValidEmail(ccMail)){
            toast.warning("Valid email required in CC")
            return
        }

        const lastMessage = messages[messages.length - 1];

        if (!lastMessage) return;

        const user = JSON.parse(localStorage.getItem('actio_user'));
        var msgReply 
        if (addSignature) {
            // Correct the spelling of 'replace'
            msgReply = reply + "<br>" + userSignature.replace("\n", "<br>") + "<br>";
        } else {
            msgReply = reply;
        }

        axios.post(`${process.env.REACT_APP_API_URL}/mailbox/campaign/${campaignId}/lead/emails/reply`, {
            stats_id: lastMessage.stats_id,
            message_id: lastMessage.message_id,
            message: msgReply,
            time: lastMessage.time,
            reply_email_body : lastMessage.email_body,
            cc : hasCC ? ccMail : null
        }, {
            headers: { Authorization: `Bearer ${user.id}@@@${user.email}` },
        })
        .then(response => {
            setMessages(prevMessages => [
                ...prevMessages,
                {
                    email_body: reply,
                    message_id: lastMessage.message_id,
                    stats_id: lastMessage.stats_id,
                    time: new Date().toISOString(),
                    type: 'SENT',
                },
            ]);
            setReply('');
            setShowModal(false);
        })
        .catch(error => {
            console.error('Error sending reply:', error);
            toast.error("Error sending reply");
        });
    };

    const handleReplyClick = () => {
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleMailTypeChange = (type) => {
        setMsgType(type);
        setSelectedLead(null)
        setSelectedUser(null)
        setMessages([])
        setLeads([]); // Clear leads when switching between inbox and sent
        setOffset(0); // Reset offset
        loadLeadsWithOffset(0, type); // Load new leads for selected mail type
        loadLeadsWithOffset(1, type); // Load new leads for selected mail type
    };

    // isLoading 
    // return( <p>Loading more leads...</p>)

    return (
        <div className='campaign-container'>
            <ToastContainer />
            <HeaderMailBox />
            {isLoading ?  
            <div className='mail-box-container'>
                <Lottie className="mail-box-animation" animationData={mailBoxAnimation} loop={true} />
            </div>
            :
            <Container fluid className="campaign-inbox-container">
                <Col xs={4} className="leads-column" style={{ overflowY: 'scroll', borderRight: '1px solid #ddd' }} onScroll={handleScroll}>
                    <div className='mail-type-container'>
                        <div
                            className={`mail-type-single ${msgType === 'inbox' ? 'inbox-header' : ''}`}
                            onClick={() => handleMailTypeChange('inbox')}
                        >
                            Inbox
                        </div>
                        <div
                            className={`mail-type-single ${msgType === 'sent' ? 'inbox-header' : ''}`}
                            onClick={() => handleMailTypeChange('sent')}
                        >
                            Sent
                        </div>
                    </div>
                    <ListGroup>
                        {leads.map((lead, index) => (
                            <div
                                key={index}
                                onClick={() => fetchMessages(lead.email_lead_map_id, lead.email_lead_id, lead)}
                                style={{ fontWeight: lead.has_new_unread_email ? 'bold' : 'normal', cursor: 'pointer' }}
                                className={selectedUser?.email === lead.email ? "slected-user" : "" }
                            >
                                <div className='single-person-image'>
                                    <img
                                        src={lead.user_data?.photo_url || DefaultProfile}
                                        alt="Person"
                                        className="person-image-email"
                                        onError={(e) => {
                                            e.target.src = DefaultProfile;
                                        }}
                                    />
                                    <div className='single-person-container'>
                                        <div className='single-person-name'>{`${lead.first_name} ${lead.last_name}`}</div>
                                        <div className='single-person-date'>
                                            
                                            {msgType === "sent" ? formatDate(lead.last_sent_time ) : formatDate(lead.last_reply_time )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))}
                    </ListGroup>
                </Col>
               
                <Col xs={8} className="chat-column" style={{ display: 'flex', flexDirection: 'column' }}>
                    {masterInboxLoader ? 
                    <div className='main-loader-container'>
                        <Lottie className="mail-loader-animation" animationData={mainLoader} loop={true} />
                    </div>
                    :
                    selectedLead ? (
                            <div className='lead-message-container'>
                                {/* <div className='user-message-container'>
                                    <div className='user-message-details'>
                                        <img
                                            src={selectedUser?.photot_url || DefaultProfile}
                                            alt="Person"
                                            className="person-image-msg-container"
                                        />
                                        <div className='user-message-details-container'>
                                            <span className='user-name-message'>{`${selectedUser.first_name} ${selectedUser.last_name}`}</span>
                                            <span className='user-name-email'>{selectedUser.email}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <button className='message-reply-btn' onClick={handleReplyClick}>Reply</button>
                                    </div>
                                </div>
                                <div className='user-message-subject'>
                                <span>Subject: {messages[0]?.subject}</span>
                                </div> */}
                                <div className="messages-container">
                                {/* <Accordion defaultActiveKey="0">
                                            {[...messages].reverse().map((email, index) => (
                                                <Card key={email.stats_id}>
                                                <Card.Header>
                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <h5>{email.subject}</h5>
                                                    <Button
                                                        onClick={() => toggleExpand(index)}
                                                        variant="link"
                                                    >
                                                        {expanded === index ? "Hide Email" : "Show Email"}
                                                    </Button>
                                                    </div>
                                                </Card.Header>

                                                <Accordion.Collapse eventKey={index.toString()} in={expanded === index}>
                                                    <Card.Body>
                                                    <div style={{ paddingBottom: "1rem" }}>
                                                        <p>{renderEmailPreview(email.email_body)}</p>
                                                        <Button
                                                        onClick={() => toggleExpand(index)}
                                                        variant="outline-secondary"
                                                        >
                                                        {expanded === index ? "Collapse" : "Expand Email"}
                                                        </Button>
                                                    </div>
                                                    {expanded === index && (
                                                        <div
                                                        dangerouslySetInnerHTML={{ __html: email.email_body }}
                                                        style={{ padding: "1rem", background: "#f9f9f9", borderRadius: "5px" }}
                                                        />
                                                    )}
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                                </Card>
                                            ))}
    </Accordion> */}                
                                    <Accordion defaultActiveKey="0">
                                        {[...messages].reverse().map((email, index) => (
                                            <Card key={email.stats_id} className="email-card">
                                                <div className='card-header'>
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        {/* Status Message at the top */}
                                                        <div style={{ display: 'flex', alignItems: 'center', padding: '5px 0'}}>
                                                            {email.type === 'REPLY' ? (
                                                                <>
                                                                <div className='user-message-box-container'>
                                                                <div className='user-message-container'>
                                                                        <div className='user-message-details'>
                                                                            <img
                                                                                src={selectedUser?.photot_url || DefaultProfile}
                                                                                alt="Person"
                                                                                className="person-image-msg-container"
                                                                            />
                                                                            <div className='user-message-details-container'>
                                                                                <span className='user-name-message'>{`${selectedUser.first_name} ${selectedUser.last_name}`}</span>
                                                                                <span className='user-name-email'>{selectedUser.email}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className='email-sent-time'>
                                                                        {formatDate(email.time)}
                                                                        <FaReply onClick={handleReplyClick} style={{ marginRight: '20px', marginLeft : '20px', fontSize : '17px', cursor : 'pointer'  }} />
                                                                        
                                                                        </div>
                                                                </div>
                                                                    {/* <div className='user-message-subject'>
                                                                    <span>Subject: {messages[0]?.subject}</span>
                                                                    </div> */}
                                                                     <div className='mail-box-subject'>
                                                                        {email.subject}
                                                                        {/* Hello user */}
                                                                        </div>
                                                                    
                                                            </div>
                                                            
                                                            </>
   
                                                               
                                                            ) : email.type === 'SENT' ? (
                                                                <div className='user-message-box-container'>
                                                                <div className='user-message-container'>
                                                                        <div className='user-message-details'>
                                                                            <img
                                                                                src={DefaultProfile}
                                                                                alt="Person"
                                                                                className="person-image-msg-container"
                                                                            />
                                                                            <div className='user-message-details-container'>
                                                                                <span className='user-name-message'>{`${parentUser.name}`}</span>
                                                                                <span className='user-name-email'>{parentUser.email}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className='email-sent-time'>
                                                                        {formatDate(email.time)}
                            
                                                                        
                                                                        </div>

                                                                    </div>
                                                                    {/* <div className='user-message-subject'>
                                                                    <span>Subject: {messages[0]?.subject}</span>
                                                                    </div> */}
                                                                    <div className='mail-box-subject'>
                                                                        {email.subject}
                                                                        {/* Hello user */}
                                                                        </div>
                                                            </div>
                                                                // <>
                                                                //     <FaPaperPlane style={{ marginRight: '5px', color: 'blue' }} />
                                                                //     <span>Email sent on {new Date(email.time).toLocaleString('en-US', { timeZone: 'GMT' })}</span>
                                                                // </>
                                                            ) : null}
                                                        </div>
                                                        {/* Email Subject */}
                                                        {/* <h5 style={{ margin: 0 }}>{email.subject}</h5> */}
                                                    </div>
                                                </div>

                                                {expanded !== index && (
                                                    <div className='email-preview-container'>
                                                        <div style={{ paddingBottom: "1rem" }}>
                                                            {/* Email preview will always show */}
                                                            <p>{renderEmailPreview(email.email_body)}</p>
                                                        </div>
                                                    </div>
                                                )}

                                                {/* Full email content inside the collapsible section */}
                                                <Accordion.Collapse eventKey={index.toString()} in={expanded === index}>
                                                <div className='email-body-container'>
                                                    <div style={{ padding: "1rem", borderRadius: "5px" }}>
                                                    <div dangerouslySetInnerHTML={{ __html: formatEmailBody(email.email_body) }} />
                                                    </div>
                                                </div>
                                                </Accordion.Collapse>

                                                {/* Custom Arrow Button at the bottom border */}
                                                <div className="arrow-button-container">
                                                    <Button
                                                        onClick={() => toggleExpand(index)}
                                                        variant="link"
                                                        className="arrow-button"
                                                    >
                                                        {expanded === index ? <FaChevronUp /> : <FaChevronDown />}
                                                    </Button>
                                                </div>
                                            </Card>
                                        ))}
                                    </Accordion>


                                
                                    {/* {[...messages].reverse().map((msg, index) => (
                                        <div 
                                            key={index} 
                                            className={`message ${msg.type === 'SENT' ? 'sent' : 'received'}`}
                                        >
                                            <div className="message-body">
                                                <p dangerouslySetInnerHTML={{ __html: msg.email_body.replace(/\n/g, '<br>') }} />
                                            </div>
                                        </div>
                                    ))} */}
                                </div>
                            </div>
                        ) : (
                            <div className='no-lead-selected-message'>
                                Your master inbox. To access the inbox, simply click on the lead.
                            </div>
                        )}

    {/* New Side Panel Implementation */}
    {showModal && (
        <div className="side-panel">
            <div className="side-panel-header">
                <button className="close-btn" onClick={handleModalClose}>✕</button>
                <span className='reply-to'>Reply to {selectedUser.email}</span>
            </div>
            <div className="side-panel-body">
                <Form.Group>
                    {/* <Form.Label>Reply:</Form.Label> */}
                    <div className='to-box'>
                        <div className='to-box-to'>
                            To :
                        </div>
                        <div className='to-box-email'>
                            {selectedUser.email}
                        </div>
                        <div className='to-box-cc-bcc' onClick={toggleCC}>
                           <span>
                            Cc
                           </span>
                        </div>
                    </div>
                    {hasCC && (<div className='to-box'>
                        <div className='to-box-to'>
                            Cc :
                        </div>
                        <div className='to-box-email'>
                           <input type="email" className='cc-input' value={ccMail} onChange={handleCCChange} />
                        </div>
                        <div className='to-box-cc-bcc' onClick={toggleCC}>
                           <span>
                            X
                           </span>
                        </div>
                    </div>)}
                    
                    {/* <Form.Control
                        as="textarea"
                        rows={15}
                        value={reply}
                        onChange={handleReplyChange}
                        placeholder="Type your reply here..."
                    /> */}
                    <ReactQuill theme="snow" value={reply} onChange={handleReplyChange} 
                    style={{ height: '400px', marginBottom: '50px' }}
                    />
                    {userSignature ? <div className='add-signature'>
                        <label>
                        <input
                            type="checkbox"
                            checked={addSignature}
                            onChange={handleSignatureChange}
                        />
                        <span className='add-signature-label'>Add signature to the mail</span>
                        
                        </label>
                    </div>:
                    <div className='add-signature'>
                    <span className='add-signature-label'>No signature found</span>
                </div>}
                    
                </Form.Group>
                <div className="side-panel-footer">
                <Button variant="secondary" onClick={handleModalClose}>Cancel</Button>
                <Button variant="primary" onClick={handleSendReply}>Send</Button>
            </div>
            </div>
            
        </div>
    )}
</Col>


            </Container>
            }
        </div>
    );
}
