import React, { useEffect, useState } from "react";
import { Image } from 'react-bootstrap';
import linkedInClip from "../../assets/images/clip_linkedin.png"
import linkedInLogo from "../../assets/images/linkedin.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const PendingTable = ({ data, columns }) => {

  console.log("data", data)
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();


  useEffect(() => {
      if (data.length === 0) {
          setCurrentPage(0);
      }
      else{
          setCurrentPage(1);
      }
  }, [data]);

  // Calculate pagination details
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data.length / itemsPerPage);
  

  function convertUTCToLocal(utcString) {
    const utcDate = new Date(utcString + 'Z'); // Append 'Z' to indicate UTC time
    return utcDate.toLocaleString(undefined, { 
        hour: 'numeric', 
        minute: 'numeric', 
        year: 'numeric', 
        month: 'numeric', 
        day: 'numeric' 
    });
}


  const handleRowClick = (search) =>{
    navigate(`/lead-history/${search.uuid}`);

  }

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };


  return (
    <div style={styles.tableContainer}>
      <table style={styles.table}>
        <thead>
        <tr>
            {columns.map((column, index) => (
                <th key={index} style={styles.tableHeader}>{column.title}</th>
            ))}
            </tr>
        </thead>
        <tbody>
  {currentItems.map((search, index) => (
    <tr
      onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#F9FAFB'}
      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''} 
      onClick={() => handleRowClick(search)}
      key={search.id}
    >
      <td style={styles.td}>
      <span style={styles.compName}>
        {search.search_query.person_locations && search.search_query.person_locations.length > 0 
          ? search.search_query.person_locations.join(', ')  // Join the array into a comma-separated string
          : "-"
        }
      </span>

      </td>
      <td style={styles.td}>
          {search.search_query.organization_num_employees_ranges && search.search_query.organization_num_employees_ranges.length > 0
            ? search.search_query.organization_num_employees_ranges
                .map(range => range.replace(/\s*,\s*/g, "-"))  // Remove spaces around the comma and replace with hyphen
                .join(', ')
            : "-"}
        </td>
        <td style={styles.td}>
                {search.search_query.person_titles && search.search_query.person_titles.length > 0
                  ? search.search_query.person_titles.join(', ')  // Join the array into a comma-separated string
                  : "-"
                }
              </td>

              <td style={styles.td}>
                {search.search_query.q_organization_keyword_tags && search.search_query.q_organization_keyword_tags.length > 0
                  ? search.search_query.q_organization_keyword_tags.join(', ')  // Join the array into a comma-separated string
                  : "-"
                }
              </td>
      <td style={styles.td}>
        {search.search_date ? convertUTCToLocal(search.search_date) : "-"}
      </td>
    </tr>
  ))}
</tbody>



      </table>
      <div style={styles.pagination}>
        <button onClick={handlePreviousPage} disabled={currentPage === 1} style={styles.button}>
            <FontAwesomeIcon icon={faArrowLeft} />
            <span style={{marginLeft : "8px"}}>
            Previous
            </span>
          
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages} style={styles.button}>
        <span style={{marginRight : "8px"}}>
            Next
            </span>
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </div>
  );
};

const styles = {
    tableContainer: {
        width: "100%",
        margin: "0 auto",
        // borderRadius: "8px", // Rounded edges for the container
        // overflow: "hidden",   // Ensures the table edges are rounded
        // boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Optional: Adds a subtle shadow
        padding : "10px"
      },
      table: {
        width: "100%",
        // borderCollapse: "collapse",
        borderRadius: "10px", // Rounded edges for the table
        // overflow: "hidden",  // Ensures the table edges are rounded
        border : "1px solid #EAECF0"
      },
      tableHeader: {
    // backgroundColor: "#f4f4f4",
    textAlign: "left",
    padding: "10px",
    fontSize: "0.9rem",
    color : " #475467",
    borderBottom : "1px solid #EAECF0",
    fontWeight : "bolder",
    textAlign : "center"
  },
  td: {
    padding: "10px",
    borderBottom: "1px solid #ddd",
    fontSize: "0.9rem",
    maxWidth: "150px",
    wordWrap: "break-word", // Ensures long words wrap to the next line
    whiteSpace: "normal",   // Allows content to wrap normally
    color : "#475467",
    textAlign : "center"
    // display : "flex",
    // flexDirection : "column",
    // justifyContent : "center",
    // alignItems : "center"
  },
  avatar: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    marginRight: "10px",
  },
  icon: {
    width: "20px",
    height: "20px",
    marginRight: "10px",
  },
  pagination: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // margin: "10px 0",
    border : "1px solid #EAECF0",
    padding: "15px 10px"
  },
  button: {
    padding: "5px 10px",
    cursor: "pointer",
    backgroundColor: "#fff",
    color: "#000",
    border: "1px solid #EAECF0",
    borderRadius: "4px",
    padding:"5px 10px"
  },
  compName : {
    fontWeight : "500",
    color : "#101828"
  },
  linkedinButton: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '5px 10px',
    fontSize: '0.8rem',
    color: '#0077B5', // LinkedIn blue color
    backgroundColor: '#E8F4FC', // Light blue background
    border: '1px solid #0077B5',
    borderRadius: '20px', // Rounded corners
    textDecoration: 'none', // Remove underline from the link
    fontWeight: '500',
  },
  notAvailabale: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '5px 10px',
    fontSize: '0.8rem',
    color: '#0077B5', // LinkedIn blue color
    backgroundColor: '#E8F4FC', // Light blue background
    border: '1px solid #0077B5',
    borderRadius: '20px', // Rounded corners
    textDecoration: 'none', // Remove underline from the link
    fontWeight: '500',
  },
};

export default PendingTable;
