import React, { useState } from 'react';
import { Image, Modal, Button } from 'react-bootstrap';
import { FaHome, FaUserFriends, FaEnvelope, FaLayerGroup, FaQuestionCircle, FaCog, FaSearch, FaList } from 'react-icons/fa';
import { NavLink, useLocation } from 'react-router-dom';
import Logo from '../../assets/images/Logo_wrap.png';
import Logout from '../../assets/images/logout.png';
const NewSidebar = () => {
  const user = JSON.parse(localStorage.getItem('actio_user'));
  const location = useLocation();
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const isActive = (path) => {
    if (location.pathname === path) return true;
    if (path !== '/' && location.pathname.startsWith(path)) return true;
    return false;
  };

  const handleLogout = () => {
    // Logic to handle logout (e.g., clear user data, redirect to login page)
    localStorage.removeItem('actio_user');
    window.location.href = '/login';
  };

  const toggleLogoutModal = () => {
    setShowLogoutModal(!showLogoutModal);
  };

  return (
    <div style={styles.sidebar}>
      <div style={styles.logoContainer}>
        <img src={Logo} alt="Actio Logo" style={styles.logo} />
      </div>

      <div style={styles.menuList}>
        <NavLink
          exact
          to="/"
          style={isActive('/') ? styles.menuItemActive : styles.menuItem}
        >
          <FaHome style={styles.icon} /> Home
        </NavLink>
        <NavLink
          to="/find-leads"
          style={
            isActive('/find-leads') || isActive('/lead-history/')
              ? styles.menuItemActive
              : styles.menuItem
          }
        >
          <FaSearch style={styles.icon} />Search Leads
        </NavLink>
        <NavLink
          to="/search-history"
          style={
            isActive('/search-history') || isActive('/lead-history/')
              ? styles.menuItemActive
              : styles.menuItem
          }
        >
          <FaUserFriends style={styles.icon} /> Pending Emails
        </NavLink>
        <NavLink
          to="/sequences"
          style={isActive('/sequences') ? styles.menuItemActive : styles.menuItem}
        >
          <FaList style={styles.icon} /> Sequences
        </NavLink>
        <NavLink
          to="/saved-leads"
          style={isActive('/saved-leads') ? styles.menuItemActive : styles.menuItem}
        >
          <FaEnvelope style={styles.icon} /> Approved Leads
        </NavLink>
        {/* <NavLink
          to="/campaign-list"
          style={isActive('/campaign-list') ? styles.menuItemActive : styles.menuItem}
        >
          <FaLayerGroup style={styles.icon} /> Campaign List
        </NavLink> */}
        <NavLink
          to="/mail-box"
          style={isActive('/mail-box') ? styles.menuItemActive : styles.menuItem}
        >
          <FaEnvelope style={styles.icon} /> Mailbox
        </NavLink>
      </div>

      <div style={styles.footer}>
        <div style={styles.menuList}>
          <NavLink
            to="/support"
            style={isActive('/support') ? styles.menuItemActive : styles.menuItem}
          >
            <FaQuestionCircle style={styles.icon} /> Support
          </NavLink>
          <NavLink
            to="/setting"
            style={isActive('/setting') ? styles.menuItemActive : styles.menuItem}
          >
            <FaCog style={styles.icon} /> Settings
          </NavLink>
        </div>
        {user ?
          <div style={styles.profile}>
            <Image src={Logo} roundedCircle style={styles.avatar} />
            <div style={{ width: '100%' }}>
              <div style={styles.profileName}>
                {user.name}
                <span onClick={toggleLogoutModal} style={{ cursor: 'pointer' }}>
                  <img src={Logout} alt="Logout" style={styles.logo} />
                </span>
              </div>
            </div>
          </div> : <></>}

      </div>

      {/* Logout Modal */}
      <Modal show={showLogoutModal} onHide={toggleLogoutModal}>
        <Modal.Header closeButton>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to logout?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleLogoutModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const styles = {
  sidebar: {
    width: '17em',
    height: '100vh',
    backgroundColor: '#fff',
    borderRight: '1px solid #e0e0e0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  logoContainer: {
    textAlign: 'center',
    padding: '20px',
  },
  logo: {
    maxHeight: '40px',
  },
  menuList: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 500
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    fontSize: '16px',
    textDecoration: 'none',
    color: '#000',
    transition: 'background-color 0.3s',
  },
  menuItemActive: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    fontSize: '16px',
    textDecoration: 'none',
    color: '#007AFF',
    transition: 'background-color 0.3s',
  },
  active: {
    backgroundColor: 'red',
  },
  icon: {
    marginRight: '10px',
  },
  footer: {
    borderTop: '1px solid #e0e0e0',
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    padding: '10px 20px',
    marginBottom: '10px',
  },
  avatar: {
    width: '40px',
    marginRight: '10px',
  },
  profileName: {
    fontSize: '16px',
    fontWeight: 'bold',
    display: "flex",
    justifyContent: "space-between"
  },
  profileHandle: {
    fontSize: '14px',
    color: '#888',
  },
};

export default NewSidebar;
