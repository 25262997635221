// @ts-nocheck
// EmailSequence.jsx

import React from "react";
import styled from "styled-components";
import { Plus, Clock, Trash2, Save } from "lucide-react";
import Button from "./Button";
import EmailCard from "./EmailCard";
import Spinner from "./Spinner";

const EmailSequenceContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 70vh;
`;

const AddEmailButton = styled(Button)`
  width: 100%;
  height: 48px;
  margin-top: 16px;
`;

const EmailSequence = ({
  sequences,
  loadingSequences,
  handleAddSequence,
  handleSequenceChange,
  handleDeleteSequence,
  editingSequence,
  setEditingSequence,
  handleSaveSequences,
  isSaving,
}) => {
  return (
    <EmailSequenceContainer>
      {loadingSequences ? (
        <div>Loading sequences...</div>
      ) : (
        sequences.map((sequence) => (
          <EmailCard
            key={sequence.clientId}
            sequence={sequence}
            editingSequence={editingSequence}
            setEditingSequence={setEditingSequence}
            handleSequenceChange={handleSequenceChange}
            handleDeleteSequence={handleDeleteSequence}
          />
        ))
      )}
      <AddEmailButton variant="outline" onClick={handleAddSequence}>
        <Plus size={16} style={{ marginRight: "8px" }} /> Add Email
      </AddEmailButton>
      {sequences.length > 0 && (
        <div
          style={{
            marginTop: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 20,
            flexDirection: "column",
            width: "100%",
            flex: 1,
          }}
        >
          <Button
            variant="primary"
            style={{ width: "200px", padding: "10px 20px" }}
            onClick={handleSaveSequences}
            disabled={isSaving}
          >
            {isSaving ? (
              <>
                <Spinner size={16} /> Saving...
              </>
            ) : (
              <>
                <Save size={16} /> Save All Changes
              </>
            )}
          </Button>
        </div>
      )}
    </EmailSequenceContainer>
  );
};

export default EmailSequence;
