// @ts-nocheck
// Button.jsx

import styled from "styled-components";

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${({ variant }) => (variant === "outline" ? "4px 8px" : "6px 12px")};
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  gap: 8px;
  transition: background-color 0.2s, border-color 0.2s;
  border: ${({ variant }) =>
    variant === "outline" || variant === "danger"
      ? "1px solid #d1d5db"
      : "none"};
  background-color: ${({ variant }) =>
    variant === "primary"
      ? "#3b82f6"
      : variant === "outline" || variant === "danger"
      ? "transparent"
      : "#3b82f6"};
  color: ${({ variant }) => (variant === "primary" ? "#fff" : "#374151")};

  &:hover {
    background-color: ${({ variant }) => {
      if (variant === "primary") return "#2563eb";
      if (variant === "outline" || variant === "danger") return "#f9fafb";
      return "#2563eb";
    }};

    ${({ variant }) =>
      variant === "danger" &&
      `
      color: #ef4444;
      border-color: #ef4444;
    `}
  }

  & + & {
    margin-left: 8px;
  }

  ${({ fullWidth }) =>
    fullWidth &&
    `
    width: 100%;
    justify-content: center;
  `}
`;

export default Button;
