// import React, { useState, useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import HeadersHome from '../components/Headers/HeadersHome';
// import NewSearchBar from '../components/SearchBar/NewSearchBar';
// import { ToastContainer } from 'react-toastify';
// import EmailManager from '../components/EmailManager/EmailManager';
// import { useParams } from 'react-router-dom';
// import bgImage from "../assets/images/bg_search.png"
// import { Grid, Card, Typography, Box, Button } from '@mui/material';
// import MailIcon from '@mui/icons-material/Mail';
// import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// import ReplyIcon from '@mui/icons-material/Reply';
// import ThumbUpIcon from '@mui/icons-material/ThumbUp';
// import BlockIcon from '@mui/icons-material/Block';
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
// import axios from 'axios';
// import mainLoader from "../assets/images/AnimationLoader.json"
// import Lottie from "lottie-react";

// export default function StatsHome() {
//     const navigate = useNavigate();
//     const location = useLocation();
//     const [data, setData] = useState(null);
//     const [showLoader, setShowLoader] = useState(true)

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const user = JSON.parse(localStorage.getItem('actio_user'));
//                 const authToken = `Bearer ${user.id}@@@${user.email}`;
//                 const response = await axios.get(`${process.env.REACT_APP_API_URL}/campaign_list/stats`, {
//                     headers: {
//                         'Authorization': authToken,
//                     },
//                 });
//                 setData(response.data.data.data.results[0]);
//                 setShowLoader(false)
//             } catch (error) {
//                 console.error("Error fetching campaign stats:", error);
//                 setShowLoader(false)
//             }
//         };

//         fetchData();
//     }, []);

//     // Destructure data with fallbacks to prevent undefined errors
//     const {
//         sent_count = 0,
//         reply_count = 0,
//         click_count = 0,
//         open_count = 0,
//         bounce_count = 0,
//         campaign_lead_stats = {},
//         total_count = 0
//     } = data || {};

//     const positive_reply = campaign_lead_stats.interested || 0;

//     // Calculate percentages with fallback to avoid NaN
//     const calculatePercentage = (count) => {
//         return total_count ? ((count / total_count) * 100).toFixed(1) : 0;
//     };

//     // List of statistics to be displayed
//     const stats = [
//         {
//             label: 'Total Sent',
//             value: sent_count,
//             percentage: calculatePercentage(sent_count),
//             icon: <MailIcon color="primary" fontSize="large" />,
//         },
//         {
//             label: 'Opened',
//             value: open_count,
//             percentage: calculatePercentage(open_count),
//             icon: <OpenInNewIcon color="secondary" fontSize="large" />,
//         },
//         {
//             label: 'Clicked',
//             value: click_count,
//             percentage: calculatePercentage(click_count),
//             icon: <ReplyIcon color="success" fontSize="large" />,
//         },
//         {
//             label: 'Replied',
//             value: reply_count,
//             percentage: calculatePercentage(reply_count),
//             icon: <ReplyIcon color="info" fontSize="large" />,
//         },
//         {
//             label: 'Positive Reply',
//             value: positive_reply,
//             percentage: calculatePercentage(positive_reply),
//             icon: <ThumbUpIcon color="success" fontSize="large" />,
//         },
//         {
//             label: 'Bounced',
//             value: bounce_count,
//             percentage: calculatePercentage(bounce_count),
//             icon: <ErrorOutlineIcon color="error" fontSize="large" />,
//         },
//     ];

//     return (
//         <div style={{
//             backgroundImage: `url(${bgImage})`,
//             backgroundSize: "cover",
//             backgroundPosition: "center",
//         }}>
//             <ToastContainer />
//             <HeadersHome source={"pp"} setSource={"ss"} />

//             <div style={{
//                 height: "88vh",
//                 backgroundSize: "cover",
//                 backgroundPosition: "center",
//             }}>
//                 {showLoader ?
//                     <div className='loader-container' style={{
//                         width : "100%",
//                         height : "100%",
//                         display : "flex",
//                         justifyContent : "center",
//                         alignItems : "center"
//                     }}>
//                         <Lottie className="loader-animation" animationData={mainLoader} loop={true} style={{
//                             width : "250px",
//                             height : "250px"
//                         }}/>
//                     </div>
//                     :
//                     <Box sx={{ padding: 4 }}>
//                     <Grid container spacing={4}>
//                         {stats.map((stat, index) => (
//                             <Grid item xs={12} sm={6} md={4} key={index}>
//                                 <Card elevation={3} sx={{ padding: 3, textAlign: 'center' }}>
//                                     <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
//                                         {stat.icon}
//                                     </Box>
//                                     <Typography variant="h6" component="div">
//                                         {stat.label}
//                                     </Typography>
//                                     <Typography variant="h4" component="div">
//                                         {stat.value}
//                                     </Typography>
//                                     <Typography color="textSecondary">
//                                         {stat.percentage}% of total
//                                     </Typography>
//                                 </Card>
//                             </Grid>
//                         ))}
//                     </Grid>
//                 </Box>}
                
//             </div>
//         </div>
//     );
// }

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HeadersHome from '../components/Headers/HeadersHome';
import NewSearchBar from '../components/SearchBar/NewSearchBar';
import { ToastContainer } from 'react-toastify';
import EmailManager from '../components/EmailManager/EmailManager';
import { useParams } from 'react-router-dom';
import bgImage from "../assets/images/bg_search.png"
import { Grid, Card, Typography, Box, Button } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ReplyIcon from '@mui/icons-material/Reply';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import BlockIcon from '@mui/icons-material/Block';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import mainLoader from "../assets/images/AnimationLoader.json"
import Lottie from "lottie-react";

export default function StatsHome() {
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState(null);
    const [showLoader, setShowLoader] = useState(true);
    const [profileIncomplete, setProfileIncomplete] = useState(false)
    const [settingsData, setSettingsData] = useState(null);
    const [emailData, setEmailData] = useState(null);
    const [userSettingsComplete, setUserSettingsComplete] = useState(false); // For user settings status
    const [emailLinked, setEmailLinked] = useState(false); // For email linking status

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('actio_user'));
                const authToken = `Bearer ${user.id}@@@${user.email}`;
                
                // Campaign Stats API call
                const campaignResponse = await axios.get(`${process.env.REACT_APP_API_URL}/campaign_list/stats`, {
                    headers: { 'Authorization': authToken },
                });
                if (campaignResponse.data.data && campaignResponse.data.data.data.results.length > 0){
                    setData(campaignResponse.data.data.data.results[0]);
                    setShowLoader(false);
                
                }
                else{
                    // Settings API call
                        const settingsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/user/settings`, {
                            headers: { 'Authorization': authToken },
                        });
                        setSettingsData(settingsResponse.data.data);
                        
                        if (settingsResponse.data.data.company_name &&
                            settingsResponse.data.data.details &&
                            settingsResponse.data.data.features &&
                            settingsResponse.data.data.performance &&
                            settingsResponse.data.data.problems &&
                            settingsResponse.data.data.prompt_description &&
                            settingsResponse.data.data.signature
                            
                            )
                            {
                            setUserSettingsComplete(true)}
            
                        // Email API call
                        const emailResponse = await axios.get(`${process.env.REACT_APP_API_URL}/user/email`, {
                            headers: { 'Authorization': authToken },
                        });
                        if (emailResponse.data.data.length > 0){
                            setEmailLinked(true)

                        }
                        // setEmailData(emailResponse.data.data);
                        setProfileIncomplete(true)
                        setShowLoader(false);
                }   
                
            } catch (error) {
                console.error("Error fetching data:", error);
                setShowLoader(false);
            }
        };
    
        fetchData();
    }, []);
    

    const {
        sent_count = 0,
        reply_count = 0,
        click_count = 0,
        open_count = 0,
        bounce_count = 0,
        campaign_lead_stats = {},
        total_count = 0
    } = data || {};

    const positive_reply = campaign_lead_stats.interested || 0;

    const calculatePercentage = (count) => {
        return total_count ? ((count / total_count) * 100).toFixed(1) : 0;
    };

    const stats = [
        {
            label: 'Total Sent',
            value: sent_count,
            percentage: calculatePercentage(sent_count),
            icon: <MailIcon color="primary" fontSize="large" />,
        },
        {
            label: 'Opened',
            value: open_count,
            percentage: calculatePercentage(open_count),
            icon: <OpenInNewIcon color="secondary" fontSize="large" />,
        },
        {
            label: 'Clicked',
            value: click_count,
            percentage: calculatePercentage(click_count),
            icon: <ReplyIcon color="success" fontSize="large" />,
        },
        {
            label: 'Replied',
            value: reply_count,
            percentage: calculatePercentage(reply_count),
            icon: <ReplyIcon color="info" fontSize="large" />,
        },
        {
            label: 'Positive Reply',
            value: positive_reply,
            percentage: calculatePercentage(positive_reply),
            icon: <ThumbUpIcon color="success" fontSize="large" />,
        },
        {
            label: 'Bounced',
            value: bounce_count,
            percentage: calculatePercentage(bounce_count),
            icon: <ErrorOutlineIcon color="error" fontSize="large" />,
        },
    ];

    return (
        <div style={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}>
            <ToastContainer />
            <HeadersHome source={"pp"} setSource={"ss"} />
        
            <div style={{
                height: "88vh",
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}>
                {showLoader ? (
                    <div className='loader-container' style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <Lottie className="loader-animation" animationData={mainLoader} loop={true} style={{
                            width: "250px",
                            height: "250px"
                        }} />
                    </div>
                ) : (
                    <Box sx={{ padding: 4 }}>
                        {!profileIncomplete ? (
                            <Grid container spacing={4}>
                                {stats.map((stat, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <Card elevation={3} sx={{ padding: 3, textAlign: 'center' }}>
                                            <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                                                {stat.icon}
                                            </Box>
                                            <Typography variant="h6" component="div">
                                                {stat.label}
                                            </Typography>
                                            <Typography variant="h4" component="div">
                                                {stat.value}
                                            </Typography>
                                            <Typography color="textSecondary">
                                                {stat.percentage}% of total
                                            </Typography>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <div className='loader-container' style={{
                                // width: "100%",
                                // height: "100%",
                                // display: "flex",
                                // justifyContent: "center",
                                // alignItems: "center"
                            }}>
                            <Box mt={4} display="flex" justifyContent="space-around">
                                {/* User Settings Check */}
                                <Card elevation={3} sx={{ padding: 3, textAlign: 'center', width: '45%', border: '2px solid', borderColor: userSettingsComplete ? 'green' : 'red' }}>
                                    <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                                        {userSettingsComplete ? (
                                            <CheckCircleIcon color="success" fontSize="large" />
                                        ) : (
                                            <CancelIcon color="error" fontSize="large" />
                                        )}
                                    </Box>
                                    <Typography variant="h6">
                                        {userSettingsComplete ? 'User Settings Verified' : 'User Settings Incomplete'}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {userSettingsComplete
                                            ? 'Your settings are up to date.'
                                            : 'Please complete your user settings to proceed.'}
                                    </Typography>
                                    {userSettingsComplete ? <></>:
                                    <Button variant="contained" color="primary" onClick={() => navigate('/setting')} style={{marginTop: "10px"}}>
                                        Go to Settings
                                    </Button>
                                    }
                                </Card>

                                {/* Email Linked Check */}
                                <Card elevation={3} sx={{ padding: 3, textAlign: 'center', width: '45%', border: '2px solid', borderColor: emailLinked ? 'green' : 'red' }}>
                                    <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                                        {emailLinked ? (
                                            <CheckCircleIcon color="success" fontSize="large" />
                                        ) : (
                                            <ErrorOutlineIcon color="error" fontSize="large" />
                                        )}
                                    </Box>
                                    <Typography variant="h6">
                                        {emailLinked ? 'Email Linked' : 'No Email Linked'}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {emailLinked
                                            ? 'Your account is linked with an email address.'
                                            : 'No email linked. Please link an email to continue.'}
                                    </Typography>
                                </Card>
                            </Box>
                            {!userSettingsComplete && !emailLinked}{
                                 <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop : "20px"
                                 }}>
                                    <Button variant="contained" color="primary" onClick={() => navigate('/find-leads')} style={{marginTop: "10px"}}>
                                        Search Leads
                                    </Button>

                                 </div>
                            }
                           
                            </div>

                        )}
                    </Box>
                )}
            </div>
        </div>
        
    );
}

