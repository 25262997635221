import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import styles from './Header.module.css'; // Importing the CSS module
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch, faUpload} from '@fortawesome/free-solid-svg-icons';

const HeadersHome = ({source, setSource}) => {
  const user = JSON.parse(localStorage.getItem('actio_user'));
  const handleSourceChange = (updatedSource) => {
    setSource(updatedSource)
    
  };
  return (
    <div className={styles.headerContainer}>
      <Container fluid>
        <div className={styles.findLeadsContainer}>
          <div>
            {user ?<h2 className={styles.headerTitle}>Welcome back, {user.name}</h2> :
            <></>
            }
            
            <p className={styles.headerSubtitle}>
              Track, manage and search leads to create campaigns, or add new leads to work on.
            </p>
          </div>
         <div style={{display : "flex", justifyContent: "center", alignItems : "center"}}>
            <div className={styles.leadsFindLeadsBtn}
            onClick={() => handleSourceChange('search')}>
            <FontAwesomeIcon icon={faSearch} />
              <span style={{marginLeft: "8px"}}>
              Find Leads
              </span>
             
            </div>
            
            <div className={styles.leadsUploadBtn}
            onClick={() => handleSourceChange('csv')}>
            <FontAwesomeIcon icon={faUpload} />
            <span style={{marginLeft: "8px"}}>
            Upload
            </span>
              
            </div>
          </div>
          
        </div>
      </Container>
    </div>
  );
};

export default HeadersHome;
