import React, { useEffect, useState } from 'react';
import { Card, Spinner, Tab, Nav, Form, Button } from 'react-bootstrap';
import DataTable from '../components/DataTable';
import axios from 'axios';

export default function Setting() {
    const columns = [
        { title: "Email"}
    ];

    const [isLoading, setIsLoading] = useState(true);
    const [campaignList, setCampaignList] = useState([]);
    const [user, setUser] = useState(null);
    const [userPrompt, setUserPrompt] = useState(null);
    const [isEditable, setIsEditable] = useState(false);

    const [formData, setFormData] = useState({
        prompt_description: '',
        company_name: '',
        problems: [''],
        features: [''],
        performance: [''],
        details: [''],
        signature : ''
    });

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('actio_user'));
        fetch(process.env.REACT_APP_API_URL + '/auth-check', { 
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.id}@@@${user.email}`
            }, 
            credentials: 'include' 
        })
        .then((response) => response.json())
        .then((user) => {
            if (user) {
                console.log(user);
                setUser(user);
            }
            setIsLoading(false);
        })
        .catch(error => {
            console.log(error.message);
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const user = JSON.parse(localStorage.getItem('actio_user'));
        axios.get(process.env.REACT_APP_API_URL + '/user/email', {
            headers: {
                Authorization: `Bearer ${user.id}@@@${user.email}`,
            }
        })
        .then((response) => {
            console.log(response.data);
            setIsLoading(false);
            setCampaignList(response.data.data.map(campaign => [
                campaign.email
            ]));
        })
        .catch((error) => {
            setIsLoading(false);
            console.log(error);
        });
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const user = JSON.parse(localStorage.getItem('actio_user'));
        axios.get(process.env.REACT_APP_API_URL + '/user/settings', {
            headers: {
                'Authorization': `Bearer ${user.id}@@@${user.email}`,
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            console.log(response.data.data);
            setUserPrompt(response.data.data);
            setFormData({
                prompt_description: response.data.data.prompt_description,
                company_name: response.data.data.company_name,
                problems: response.data.data.problems,
                features:response.data.data.features,
                performance: response.data.data.performance,
                details: response.data.data.details,
                signature : response.data.data.signature
            });
            setIsLoading(false);
        })
        .catch((error) => {
            setIsLoading(false);
            console.log(error);
        });
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleArrayChange = (e, index, field) => {
        const { value } = e.target;
        console.log(value);
        const newArray = [...formData[field]];
        console.log(newArray, index);
        newArray[index] = value;
        console.log(value);
        setFormData({
            ...formData,
            [field]: newArray
        });
    };

    const addArrayField = (field) => {
        setFormData({
            ...formData,
            [field]: [...formData[field], '']
        });
    };

    const handleEditToggle = () => {
        setIsEditable(!isEditable);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const user = JSON.parse(localStorage.getItem('actio_user'));
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/user/settings', {
                ...formData
            }, {
                headers: {
                    Authorization: `Bearer ${user.id}@@@${user.email}`,
                }
            });
            console.log(response.data);
            setIsEditable(false);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Card className="rounded-5">
            <Card.Body>
                <Tab.Container defaultActiveKey="first">
                    <Nav variant="pills">
                        <Nav.Item style={{width:"200px", textAlign:"center", marginRight : "20px"}}>
                            <Nav.Link eventKey="first">Account Info</Nav.Link>
                        </Nav.Item>
                        <Nav.Item style={{width:"200px", textAlign:"center", marginRight : "20px"}}>
                            <Nav.Link eventKey="second">Mailboxes</Nav.Link>
                        </Nav.Item>
                        <Nav.Item style={{width:"200px", textAlign:"center", marginRight : "20px"}}>
                            <Nav.Link eventKey="third">Base User Prompts</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content className="p-4">
                        <Tab.Pane eventKey="first">
                            <div className="header-title">
                                <h4 className="card-title">About User</h4>
                            </div>
                            {user ? (
                                <div className="row">
                                    <div className="mt-2 col-6">
                                        <h6 className="mb-1">User ID</h6>
                                        <p>{user.id}</p>
                                    </div>
                                    <div className="mt-2 col-6">
                                        <h6 className="mb-1">Name</h6>
                                        <p>{user.name}</p>
                                    </div>
                                    <div className="mt-2 col-6">
                                        <h6 className="mb-1">Email</h6>
                                        <p>{user.email}</p>
                                    </div>
                                    <div className="mt-2 col-6">
                                        <h6 className="mb-1">Company</h6>
                                        <p>{user.company_name}</p>
                                    </div>
                                    <div className="mt-2 col-6">
                                        <h6 className="mb-1">Credits used</h6>
                                        <p>{user.user_credit} / {user.monthly_credit}</p>
                                    </div>
                                </div>
                            ) : ""}
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <h5>Campaign List</h5>
                            <div className="mt-5">
                                {isLoading ? (
                                    <div className="d-flex align-items-center mb-5 justify-content-center">
                                        <Spinner animation="border" variant="primary" role="status" />
                                        <span style={{ marginLeft: "10px" }}>Loading...</span>
                                    </div>
                                ) : (
                                    <DataTable columns={columns} data={campaignList} />
                                )}
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            <h5 style={{marginBottom : "10px"}}> Base User Prompts</h5>
                            {isEditable ? (
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="formCompanyName">
                                        <Form.Label>Product Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="company_name"
                                            value={formData.company_name}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formPromptDescription">
                                        <Form.Label style={{ marginTop: '10px' }}>Product Description</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="prompt_description"
                                            value={formData.prompt_description}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    
                                    <Form.Group controlId="formProblems">
                                        <Form.Label>Product Problems</Form.Label>
                                        {formData.problems.map((problem, index) => (
                                            <Form.Control
                                                key={index}
                                                type="text"
                                                value={problem}
                                                onChange={(e) => handleArrayChange(e, index, 'problems')}
                                                className="mb-2"
                                            />
                                        ))}
                                        <Button variant="secondary" onClick={() => addArrayField('problems')}>Add Problem</Button>
                                    </Form.Group>
                                    <Form.Group controlId="formFeatures">
                                        <Form.Label>Product Features</Form.Label>
                                        {formData.features.map((feature, index) => (
                                            <Form.Control
                                                key={index}
                                                type="text"
                                                value={feature}
                                                onChange={(e) => handleArrayChange(e, index, 'features')}
                                                className="mb-2"
                                            />
                                        ))}
                                        <Button variant="secondary" onClick={() => addArrayField('features')}>Add Feature</Button>
                                    </Form.Group>
                                    <Form.Group controlId="formPerformance">
                                        <Form.Label>Product Performance</Form.Label>
                                        {formData.performance.map((performance, index) => (
                                            <Form.Control
                                                key={index}
                                                type="text"
                                                value={performance}
                                                onChange={(e) => handleArrayChange(e, index, 'performance')}
                                                className="mb-2"
                                            />
                                        ))}
                                        <Button variant="secondary" onClick={() => addArrayField('performance')}>Add Performance</Button>
                                    </Form.Group>

                                    <Form.Group controlId="formDetails">
                                        <Form.Label>Product Overview</Form.Label>
                                        {formData.details.map((details, index) => (
                                            <Form.Control
                                                key={index}
                                                type="text"
                                                value={details}
                                                onChange={(e) => handleArrayChange(e, index, 'details')}
                                                className="mb-2"
                                            />
                                        ))}
                                        <Button variant="secondary" onClick={() => addArrayField('details')}>Add Details</Button>
                                    </Form.Group>

                                    <Form.Group controlId="formPromptDescription">
                                        <Form.Label style={{ marginTop: '10px' }}>Signature</Form.Label>
        
                                    <Form.Control
                                            as="textarea"
                                            rows={4}
                                            name="signature"
                                            value={formData.signature}
                                            onChange={handleInputChange}
                                            placeholder="Add you signature"
                                        />
                                    
                                    </Form.Group>

                                    <div style={{marginTop : "10px", display : "flex"}}>
                                    <Button style={{marginRight : "10px"}} variant="primary" type="submit">
                                        Submit
                                    </Button>
                                    <Button variant="secondary" onClick={handleEditToggle}>
                                        {isEditable ? 'Cancel' : 'Edit'}
                                    </Button>
                                    </div>
                                    
                                </Form>
                            ) : (
                                userPrompt && (
                                    <div>
                                        
                                        <p><strong>Product Name:</strong> {userPrompt.company_name}</p>
                                        <p><strong>Product Description:</strong> {userPrompt.prompt_description}</p>
                                        <p><strong>Product Problems:</strong> {userPrompt.problems.join(', ')}</p>
                                        <p><strong>Product Features:</strong> {userPrompt.features.join(', ')}</p>
                                        <p><strong>Product Performance:</strong> {userPrompt.performance.join(', ')}</p>
                                        <p><strong>Product Overview:</strong> {userPrompt.details.join(', ')}</p>
                                        <p><strong>Signature:</strong> {userPrompt.signature.split('\n').map((line, index) => (
                                            <span key={index}>{line}<br /></span>
                                        ))}</p>
                                    </div>
                                )
                            )}
                            {isEditable ? "" :<Button variant="secondary" onClick={handleEditToggle}>
                                {isEditable ? 'Cancel' : 'Edit'}
                            </Button>}
                            
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </Card.Body>
        </Card>
    );
}
