export const INDUSTRY = [
    {
      "id": "5567cd4773696439b10b0000",
      "cleaned_name": "information technology & services",
      "tag_name_unanalyzed_downcase": "information technology & services",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 721810,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.8643984,
      "_explanation": null
    },
    {
      "id": "5567cd4773696439dd350000",
      "cleaned_name": "construction",
      "tag_name_unanalyzed_downcase": "construction",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 531470,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.7335744,
      "_explanation": null
    },
    {
      "id": "5567cd467369644d39040000",
      "cleaned_name": "marketing & advertising",
      "tag_name_unanalyzed_downcase": "marketing & advertising",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 497904,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.7057816,
      "_explanation": null
    },
    {
      "id": "5567cd477369645401010000",
      "cleaned_name": "real estate",
      "tag_name_unanalyzed_downcase": "real estate",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 390492,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.6025938,
      "_explanation": null
    },
    {
      "id": "5567cddb7369644d250c0000",
      "cleaned_name": "health, wellness & fitness",
      "tag_name_unanalyzed_downcase": "health, wellness & fitness",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 340699,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.5449345,
      "_explanation": null
    },
    {
      "id": "5567cdd47369643dbf260000",
      "cleaned_name": "management consulting",
      "tag_name_unanalyzed_downcase": "management consulting",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 327912,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.5288036,
      "_explanation": null
    },
    {
      "id": "5567cd4e7369643b70010000",
      "cleaned_name": "computer software",
      "tag_name_unanalyzed_downcase": "computer software",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 305264,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.4986744,
      "_explanation": null
    },
    {
      "id": "5567cd4d736964397e020000",
      "cleaned_name": "internet",
      "tag_name_unanalyzed_downcase": "internet",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 304994,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.4983022,
      "_explanation": null
    },
    {
      "id": "5567ced173696450cb580000",
      "cleaned_name": "retail",
      "tag_name_unanalyzed_downcase": "retail",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 289296,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.4761009,
      "_explanation": null
    },
    {
      "id": "5567cdd67369643e64020000",
      "cleaned_name": "financial services",
      "tag_name_unanalyzed_downcase": "financial services",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 285078,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.4699368,
      "_explanation": null
    },
    {
      "id": "5567d1127261697f2b1d0000",
      "cleaned_name": "consumer services",
      "tag_name_unanalyzed_downcase": "consumer services",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 282703,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.4664272,
      "_explanation": null
    },
    {
      "id": "5567cdde73696439812c0000",
      "cleaned_name": "hospital & health care",
      "tag_name_unanalyzed_downcase": "hospital & health care",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 271764,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.4498855,
      "_explanation": null
    },
    {
      "id": "5567cdf27369644cfd800000",
      "cleaned_name": "automotive",
      "tag_name_unanalyzed_downcase": "automotive",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 250458,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.4157377,
      "_explanation": null
    },
    {
      "id": "5567e0e0736964198de70700",
      "cleaned_name": "restaurants",
      "tag_name_unanalyzed_downcase": "restaurants",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 210742,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.343885,
      "_explanation": null
    },
    {
      "id": "5567ce9e736964540d540000",
      "cleaned_name": "education management",
      "tag_name_unanalyzed_downcase": "education management",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 203840,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.330089,
      "_explanation": null
    },
    {
      "id": "5567ce1e7369643b806a0000",
      "cleaned_name": "food & beverages",
      "tag_name_unanalyzed_downcase": "food & beverages",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 195684,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.3132005,
      "_explanation": null
    },
    {
      "id": "5567cdbc73696439d90b0000",
      "cleaned_name": "design",
      "tag_name_unanalyzed_downcase": "design",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 190601,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.3023331,
      "_explanation": null
    },
    {
      "id": "5567ce9d7369643bc19c0000",
      "cleaned_name": "hospitality",
      "tag_name_unanalyzed_downcase": "hospitality",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 174263,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.2654381,
      "_explanation": null
    },
    {
      "id": "5567ce1f7369643b78570000",
      "cleaned_name": "accounting",
      "tag_name_unanalyzed_downcase": "accounting",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 159060,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.2280408,
      "_explanation": null
    },
    {
      "id": "5567cd8e7369645409450000",
      "cleaned_name": "events services",
      "tag_name_unanalyzed_downcase": "events services",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 154350,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.2157696,
      "_explanation": null
    },
    {
      "id": "5567cd4773696454303a0000",
      "cleaned_name": "nonprofit organization management",
      "tag_name_unanalyzed_downcase": "nonprofit organization management",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 148896,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.201113,
      "_explanation": null
    },
    {
      "id": "5567cdd37369643b80510000",
      "cleaned_name": "entertainment",
      "tag_name_unanalyzed_downcase": "entertainment",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 141775,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.1812003,
      "_explanation": null
    },
    {
      "id": "5567cd4c73696439c9030000",
      "cleaned_name": "electrical/electronic manufacturing",
      "tag_name_unanalyzed_downcase": "electrical/electronic manufacturing",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 138493,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.171706,
      "_explanation": null
    },
    {
      "id": "5567cdd87369643bc12f0000",
      "cleaned_name": "leisure, travel & tourism",
      "tag_name_unanalyzed_downcase": "leisure, travel & tourism",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 137952,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.1701208,
      "_explanation": null
    },
    {
      "id": "5567cd49736964541d010000",
      "cleaned_name": "professional training & coaching",
      "tag_name_unanalyzed_downcase": "professional training & coaching",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 136653,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.166291,
      "_explanation": null
    },
    {
      "id": "5567cd4e7369644cf93b0000",
      "cleaned_name": "transportation/trucking/railroad",
      "tag_name_unanalyzed_downcase": "transportation/trucking/railroad",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 126539,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.1352566,
      "_explanation": null
    },
    {
      "id": "5567ce1f7369644d391c0000",
      "cleaned_name": "law practice",
      "tag_name_unanalyzed_downcase": "law practice",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 122972,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.1237602,
      "_explanation": null
    },
    {
      "id": "5567cd82736964540d0b0000",
      "cleaned_name": "apparel & fashion",
      "tag_name_unanalyzed_downcase": "apparel & fashion",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 122937,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.1236459,
      "_explanation": null
    },
    {
      "id": "5567cdb77369645401080000",
      "cleaned_name": "architecture & planning",
      "tag_name_unanalyzed_downcase": "architecture & planning",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 120834,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.1167206,
      "_explanation": null
    },
    {
      "id": "5567ce2673696453d95c0000",
      "cleaned_name": "mechanical or industrial engineering",
      "tag_name_unanalyzed_downcase": "mechanical or industrial engineering",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 119210,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.111296,
      "_explanation": null
    },
    {
      "id": "5567cdd973696453d93f0000",
      "cleaned_name": "insurance",
      "tag_name_unanalyzed_downcase": "insurance",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 116915,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.103513,
      "_explanation": null
    },
    {
      "id": "5567cd4c7369644d39080000",
      "cleaned_name": "telecommunications",
      "tag_name_unanalyzed_downcase": "telecommunications",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 111199,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.0834991,
      "_explanation": null
    },
    {
      "id": "5567e0e37369640e5ac10c00",
      "cleaned_name": "human resources",
      "tag_name_unanalyzed_downcase": "human resources",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 109546,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.077535,
      "_explanation": null
    },
    {
      "id": "5567e09973696410db020800",
      "cleaned_name": "staffing & recruiting",
      "tag_name_unanalyzed_downcase": "staffing & recruiting",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 105366,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.0620779,
      "_explanation": null
    },
    {
      "id": "5567ce227369644eed290000",
      "cleaned_name": "sports",
      "tag_name_unanalyzed_downcase": "sports",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 104265,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.0579132,
      "_explanation": null
    },
    {
      "id": "5567ce2d7369644d25250000",
      "cleaned_name": "legal services",
      "tag_name_unanalyzed_downcase": "legal services",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 102711,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.0519663,
      "_explanation": null
    },
    {
      "id": "5567cdd97369645624020000",
      "cleaned_name": "oil & energy",
      "tag_name_unanalyzed_downcase": "oil & energy",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 101398,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.0468774,
      "_explanation": null
    },
    {
      "id": "5567e0ea7369640d2ba31600",
      "cleaned_name": "media production",
      "tag_name_unanalyzed_downcase": "media production",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 100429,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.0430831,
      "_explanation": null
    },
    {
      "id": "5567cd4973696439d53c0000",
      "cleaned_name": "machinery",
      "tag_name_unanalyzed_downcase": "machinery",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 100171,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.0420673,
      "_explanation": null
    },
    {
      "id": "5567d01e73696457ee100000",
      "cleaned_name": "wholesale",
      "tag_name_unanalyzed_downcase": "wholesale",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 94677,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.0198512,
      "_explanation": null
    },
    {
      "id": "5567ce987369643b789e0000",
      "cleaned_name": "consumer goods",
      "tag_name_unanalyzed_downcase": "consumer goods",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 93184,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.0136124,
      "_explanation": null
    },
    {
      "id": "5567cd4f736964540d050000",
      "cleaned_name": "music",
      "tag_name_unanalyzed_downcase": "music",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 92813,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 1.012048,
      "_explanation": null
    },
    {
      "id": "5567cd4f7369644cfd250000",
      "cleaned_name": "photography",
      "tag_name_unanalyzed_downcase": "photography",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 87295,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.9880905,
      "_explanation": null
    },
    {
      "id": "5567d0467369645dbc200000",
      "cleaned_name": "medical practice",
      "tag_name_unanalyzed_downcase": "medical practice",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 85865,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.98166007,
      "_explanation": null
    },
    {
      "id": "5567e1ae73696423dc040000",
      "cleaned_name": "cosmetics",
      "tag_name_unanalyzed_downcase": "cosmetics",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 81305,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.9604946,
      "_explanation": null
    },
    {
      "id": "5567ce5b736964540d280000",
      "cleaned_name": "environmental services",
      "tag_name_unanalyzed_downcase": "environmental services",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 79858,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.9535567,
      "_explanation": null
    },
    {
      "id": "5567cd4d73696439d9040000",
      "cleaned_name": "graphic design",
      "tag_name_unanalyzed_downcase": "graphic design",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 77031,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.93967396,
      "_explanation": null
    },
    {
      "id": "5567e0fa73696410e4c51200",
      "cleaned_name": "business supplies & equipment",
      "tag_name_unanalyzed_downcase": "business supplies & equipment",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 75523,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.9320829,
      "_explanation": null
    },
    {
      "id": "5567cd49736964540d020000",
      "cleaned_name": "renewables & environment",
      "tag_name_unanalyzed_downcase": "renewables & environment",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 74529,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.9270057,
      "_explanation": null
    },
    {
      "id": "5567ce9c7369643bc9980000",
      "cleaned_name": "facilities services",
      "tag_name_unanalyzed_downcase": "facilities services",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 72938,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.91875356,
      "_explanation": null
    },
    {
      "id": "5567ce5b73696439a17a0000",
      "cleaned_name": "publishing",
      "tag_name_unanalyzed_downcase": "publishing",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 71491,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.9111096,
      "_explanation": null
    },
    {
      "id": "5567e1b3736964208b280000",
      "cleaned_name": "food production",
      "tag_name_unanalyzed_downcase": "food production",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 71024,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.9086137,
      "_explanation": null
    },
    {
      "id": "5567cd4d73696439d9030000",
      "cleaned_name": "arts & crafts",
      "tag_name_unanalyzed_downcase": "arts & crafts",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 70247,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.9044288,
      "_explanation": null
    },
    {
      "id": "5567e1a17369641ea9d30100",
      "cleaned_name": "building materials",
      "tag_name_unanalyzed_downcase": "building materials",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 66996,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.8864682,
      "_explanation": null
    },
    {
      "id": "5567e13a73696418756e0200",
      "cleaned_name": "civil engineering",
      "tag_name_unanalyzed_downcase": "civil engineering",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 65655,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.87883765,
      "_explanation": null
    },
    {
      "id": "5567e0f27369640e5aed0c00",
      "cleaned_name": "religious institutions",
      "tag_name_unanalyzed_downcase": "religious institutions",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 63938,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.8688677,
      "_explanation": null
    },
    {
      "id": "5567ce5973696453d9780000",
      "cleaned_name": "public relations & communications",
      "tag_name_unanalyzed_downcase": "public relations & communications",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 60403,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.84759116,
      "_explanation": null
    },
    {
      "id": "5567cd4c73696453e1300000",
      "cleaned_name": "higher education",
      "tag_name_unanalyzed_downcase": "higher education",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 59989,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.8450298,
      "_explanation": null
    },
    {
      "id": "5567cd4d7369644d513e0000",
      "cleaned_name": "printing",
      "tag_name_unanalyzed_downcase": "printing",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 59956,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.84482497,
      "_explanation": null
    },
    {
      "id": "5567cede73696440d0040000",
      "cleaned_name": "furniture",
      "tag_name_unanalyzed_downcase": "furniture",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 59535,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.84220344,
      "_explanation": null
    },
    {
      "id": "5567e3f3736964395d7a0000",
      "cleaned_name": "mining & metals",
      "tag_name_unanalyzed_downcase": "mining & metals",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 59215,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.8402002,
      "_explanation": null
    },
    {
      "id": "5567cd4973696439b9010000",
      "cleaned_name": "logistics & supply chain",
      "tag_name_unanalyzed_downcase": "logistics & supply chain",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 57579,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.82981175,
      "_explanation": null
    },
    {
      "id": "5567e09f736964160ebb0100",
      "cleaned_name": "research",
      "tag_name_unanalyzed_downcase": "research",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 57071,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.82653475,
      "_explanation": null
    },
    {
      "id": "5567e0eb73696410e4bd1200",
      "cleaned_name": "pharmaceuticals",
      "tag_name_unanalyzed_downcase": "pharmaceuticals",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 56909,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.8254845,
      "_explanation": null
    },
    {
      "id": "5567d02b7369645d8b140000",
      "cleaned_name": "individual & family services",
      "tag_name_unanalyzed_downcase": "individual & family services",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 56276,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.8213563,
      "_explanation": null
    },
    {
      "id": "5567e1b97369641ea9690200",
      "cleaned_name": "medical devices",
      "tag_name_unanalyzed_downcase": "medical devices",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 54465,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.80932397,
      "_explanation": null
    },
    {
      "id": "5567cdda7369644eed130000",
      "cleaned_name": "civic & social organization",
      "tag_name_unanalyzed_downcase": "civic & social organization",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 53178,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.80056584,
      "_explanation": null
    },
    {
      "id": "5567e19c7369641c48e70100",
      "cleaned_name": "e-learning",
      "tag_name_unanalyzed_downcase": "e-learning",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 52673,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.79708046,
      "_explanation": null
    },
    {
      "id": "5567e19b7369641ead740000",
      "cleaned_name": "security & investigations",
      "tag_name_unanalyzed_downcase": "security & investigations",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 51116,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.7861549,
      "_explanation": null
    },
    {
      "id": "5567e21e73696426a1030000",
      "cleaned_name": "chemicals",
      "tag_name_unanalyzed_downcase": "chemicals",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 49343,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.7733695,
      "_explanation": null
    },
    {
      "id": "5567cd527369643981050000",
      "cleaned_name": "government administration",
      "tag_name_unanalyzed_downcase": "government administration",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 47906,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.76272357,
      "_explanation": null
    },
    {
      "id": "5567cdb373696439dd540000",
      "cleaned_name": "online media",
      "tag_name_unanalyzed_downcase": "online media",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 47701,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.7611833,
      "_explanation": null
    },
    {
      "id": "5567e0bc7369641d11550200",
      "cleaned_name": "investment management",
      "tag_name_unanalyzed_downcase": "investment management",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 46912,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.75520384,
      "_explanation": null
    },
    {
      "id": "5567cd4f7369644d2d010000",
      "cleaned_name": "farming",
      "tag_name_unanalyzed_downcase": "farming",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 46756,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.75401175,
      "_explanation": null
    },
    {
      "id": "5567cdd973696439a1370000",
      "cleaned_name": "writing & editing",
      "tag_name_unanalyzed_downcase": "writing & editing",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 46665,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.7533149,
      "_explanation": null
    },
    {
      "id": "5567e1327369641d91ce0300",
      "cleaned_name": "textiles",
      "tag_name_unanalyzed_downcase": "textiles",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 46251,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.75013024,
      "_explanation": null
    },
    {
      "id": "5567ce2773696454308f0000",
      "cleaned_name": "mental health care",
      "tag_name_unanalyzed_downcase": "mental health care",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 44294,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.7347518,
      "_explanation": null
    },
    {
      "id": "5567cdd97369645430680000",
      "cleaned_name": "primary/secondary education",
      "tag_name_unanalyzed_downcase": "primary/secondary education",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 44065,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.7329162,
      "_explanation": null
    },
    {
      "id": "5567e0f973696416d34e0200",
      "cleaned_name": "broadcast media",
      "tag_name_unanalyzed_downcase": "broadcast media",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 42098,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.716821,
      "_explanation": null
    },
    {
      "id": "5567d08e7369645dbc4b0000",
      "cleaned_name": "biotechnology",
      "tag_name_unanalyzed_downcase": "biotechnology",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 41577,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.71245605,
      "_explanation": null
    },
    {
      "id": "5567e0c97369640d2b3b1600",
      "cleaned_name": "information services",
      "tag_name_unanalyzed_downcase": "information services",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 41322,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.71030354,
      "_explanation": null
    },
    {
      "id": "5567ce9c7369644eed680000",
      "cleaned_name": "international trade & development",
      "tag_name_unanalyzed_downcase": "international trade & development",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 39609,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.69556046,
      "_explanation": null
    },
    {
      "id": "5567cdd7736964540d130000",
      "cleaned_name": "motion pictures & film",
      "tag_name_unanalyzed_downcase": "motion pictures & film",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 39352,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.6933048,
      "_explanation": null
    },
    {
      "id": "5567e1947369641ead570000",
      "cleaned_name": "consumer electronics",
      "tag_name_unanalyzed_downcase": "consumer electronics",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 39196,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.69192976,
      "_explanation": null
    },
    {
      "id": "5567ce237369644ee5490000",
      "cleaned_name": "banking",
      "tag_name_unanalyzed_downcase": "banking",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 38990,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.6901074,
      "_explanation": null
    },
    {
      "id": "5567ce9d7369645430c50000",
      "cleaned_name": "import & export",
      "tag_name_unanalyzed_downcase": "import & export",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 38876,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.6890957,
      "_explanation": null
    },
    {
      "id": "5567e1337369641ad2970000",
      "cleaned_name": "industrial automation",
      "tag_name_unanalyzed_downcase": "industrial automation",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 38677,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.6873238,
      "_explanation": null
    },
    {
      "id": "5567e134736964214f5e0000",
      "cleaned_name": "recreational facilities & services",
      "tag_name_unanalyzed_downcase": "recreational facilities & services",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 36796,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.67020875,
      "_explanation": null
    },
    {
      "id": "5567e0af7369641ec7300000",
      "cleaned_name": "performing arts",
      "tag_name_unanalyzed_downcase": "performing arts",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 35169,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.65484047,
      "_explanation": null
    },
    {
      "id": "5567e2127369642420170000",
      "cleaned_name": "utilities",
      "tag_name_unanalyzed_downcase": "utilities",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 34656,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.6498798,
      "_explanation": null
    },
    {
      "id": "5567e113736964198d5e0800",
      "cleaned_name": "sporting goods",
      "tag_name_unanalyzed_downcase": "sporting goods",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 33385,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.6373396,
      "_explanation": null
    },
    {
      "id": "5567e2097369642420150000",
      "cleaned_name": "fine art",
      "tag_name_unanalyzed_downcase": "fine art",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 33225,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.635735,
      "_explanation": null
    },
    {
      "id": "5567e0bf7369641d115f0200",
      "cleaned_name": "airlines/aviation",
      "tag_name_unanalyzed_downcase": "airlines/aviation",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 31674,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.6198652,
      "_explanation": null
    },
    {
      "id": "5567cd877369644cf94b0000",
      "cleaned_name": "computer & network security",
      "tag_name_unanalyzed_downcase": "computer & network security",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 30733,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.60994637,
      "_explanation": null
    },
    {
      "id": "5567cd8273696439b1240000",
      "cleaned_name": "maritime",
      "tag_name_unanalyzed_downcase": "maritime",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 30524,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.60771227,
      "_explanation": null
    },
    {
      "id": "5567cda97369644cfd3e0000",
      "cleaned_name": "luxury goods & jewelry",
      "tag_name_unanalyzed_downcase": "luxury goods & jewelry",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 30365,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.60600495,
      "_explanation": null
    },
    {
      "id": "5567ce9673696439d5c10000",
      "cleaned_name": "veterinary",
      "tag_name_unanalyzed_downcase": "veterinary",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 29361,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.59506613,
      "_explanation": null
    },
    {
      "id": "5567e1587369641c48370000",
      "cleaned_name": "venture capital & private equity",
      "tag_name_unanalyzed_downcase": "venture capital & private equity",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 28483,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.5852689,
      "_explanation": null
    },
    {
      "id": "5567cd4d7369643b78100000",
      "cleaned_name": "wine & spirits",
      "tag_name_unanalyzed_downcase": "wine & spirits",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 28389,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.5842068,
      "_explanation": null
    },
    {
      "id": "5567cdda7369644cf95d0000",
      "cleaned_name": "plastics",
      "tag_name_unanalyzed_downcase": "plastics",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 27718,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.57654864,
      "_explanation": null
    },
    {
      "id": "5567e0dd73696416d3c20100",
      "cleaned_name": "aviation & aerospace",
      "tag_name_unanalyzed_downcase": "aviation & aerospace",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 26849,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.5664257,
      "_explanation": null
    },
    {
      "id": "5567e1887369641d68d40100",
      "cleaned_name": "commercial real estate",
      "tag_name_unanalyzed_downcase": "commercial real estate",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 26419,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.561328,
      "_explanation": null
    },
    {
      "id": "5567cd8b736964540d0f0000",
      "cleaned_name": "computer games",
      "tag_name_unanalyzed_downcase": "computer games",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 26302,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.55993056,
      "_explanation": null
    },
    {
      "id": "5567e36973696431a4480000",
      "cleaned_name": "packaging & containers",
      "tag_name_unanalyzed_downcase": "packaging & containers",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 24602,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.5391012,
      "_explanation": null
    },
    {
      "id": "5567e09473696410dbf00700",
      "cleaned_name": "executive office",
      "tag_name_unanalyzed_downcase": "executive office",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 23868,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.52978957,
      "_explanation": null
    },
    {
      "id": "5567e0d47369641233eb0600",
      "cleaned_name": "computer hardware",
      "tag_name_unanalyzed_downcase": "computer hardware",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 23558,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.52579606,
      "_explanation": null
    },
    {
      "id": "5567cdbe7369643b78360000",
      "cleaned_name": "computer networking",
      "tag_name_unanalyzed_downcase": "computer networking",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 22516,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.5120971,
      "_explanation": null
    },
    {
      "id": "5567e1387369641ec75d0200",
      "cleaned_name": "market research",
      "tag_name_unanalyzed_downcase": "market research",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 22213,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.5080312,
      "_explanation": null
    },
    {
      "id": "5567d04173696457ee520000",
      "cleaned_name": "outsourcing/offshoring",
      "tag_name_unanalyzed_downcase": "outsourcing/offshoring",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 21441,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.49749634,
      "_explanation": null
    },
    {
      "id": "5567e2907369642433e60200",
      "cleaned_name": "program development",
      "tag_name_unanalyzed_downcase": "program development",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 18734,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.45839608,
      "_explanation": null
    },
    {
      "id": "5567e1097369641d91230300",
      "cleaned_name": "translation & localization",
      "tag_name_unanalyzed_downcase": "translation & localization",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 17749,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.44324732,
      "_explanation": null
    },
    {
      "id": "5567ce9673696453d99f0000",
      "cleaned_name": "philanthropy",
      "tag_name_unanalyzed_downcase": "philanthropy",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 17586,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.44068873,
      "_explanation": null
    },
    {
      "id": "5567cd4a7369643ba9010000",
      "cleaned_name": "public safety",
      "tag_name_unanalyzed_downcase": "public safety",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 17468,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.43882704,
      "_explanation": null
    },
    {
      "id": "5567e27c7369642ade490000",
      "cleaned_name": "alternative medicine",
      "tag_name_unanalyzed_downcase": "alternative medicine",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 16330,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.42045087,
      "_explanation": null
    },
    {
      "id": "5567e15373696422aa0a0000",
      "cleaned_name": "museums & institutions",
      "tag_name_unanalyzed_downcase": "museums & institutions",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 16312,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.42015386,
      "_explanation": null
    },
    {
      "id": "5567e127736964181e700200",
      "cleaned_name": "warehousing",
      "tag_name_unanalyzed_downcase": "warehousing",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 16202,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.41833442,
      "_explanation": null
    },
    {
      "id": "5567e1097369641b5f810500",
      "cleaned_name": "defense & space",
      "tag_name_unanalyzed_downcase": "defense & space",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 16111,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.4168235,
      "_explanation": null
    },
    {
      "id": "5567cd4a73696439a9010000",
      "cleaned_name": "newspapers",
      "tag_name_unanalyzed_downcase": "newspapers",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 16027,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.4154241,
      "_explanation": null
    },
    {
      "id": "5567e97f7369641e57730100",
      "cleaned_name": "paper & forest products",
      "tag_name_unanalyzed_downcase": "paper & forest products",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 15309,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.40327498,
      "_explanation": null
    },
    {
      "id": "5567e0e073696408da441e00",
      "cleaned_name": "law enforcement",
      "tag_name_unanalyzed_downcase": "law enforcement",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 15113,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.3998986,
      "_explanation": null
    },
    {
      "id": "5567e1ab7369641f6d660100",
      "cleaned_name": "investment banking",
      "tag_name_unanalyzed_downcase": "investment banking",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 14672,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.39220434,
      "_explanation": null
    },
    {
      "id": "5567e29b736964256c370100",
      "cleaned_name": "government relations",
      "tag_name_unanalyzed_downcase": "government relations",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 13874,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.3779252,
      "_explanation": null
    },
    {
      "id": "5567d2ad7261697f2b1f0100",
      "cleaned_name": "fund-raising",
      "tag_name_unanalyzed_downcase": "fund-raising",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 13828,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.3770876,
      "_explanation": null
    },
    {
      "id": "5567e1de7369642069ea0100",
      "cleaned_name": "think tanks",
      "tag_name_unanalyzed_downcase": "think tanks",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 12783,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.3576109,
      "_explanation": null
    },
    {
      "id": "5567cd4f736964397e030000",
      "cleaned_name": "glass, ceramics & concrete",
      "tag_name_unanalyzed_downcase": "glass, ceramics & concrete",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 11631,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.3350766,
      "_explanation": null
    },
    {
      "id": "5567cdb773696439a9080000",
      "cleaned_name": "capital markets",
      "tag_name_unanalyzed_downcase": "capital markets",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 11397,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.33035287,
      "_explanation": null
    },
    {
      "id": "5567e0d87369640e5aa30c00",
      "cleaned_name": "semiconductors",
      "tag_name_unanalyzed_downcase": "semiconductors",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 11120,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.32469392,
      "_explanation": null
    },
    {
      "id": "5567e36f73696431a4970000",
      "cleaned_name": "animation",
      "tag_name_unanalyzed_downcase": "animation",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 10865,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.31941837,
      "_explanation": null
    },
    {
      "id": "5567e25f736964256cff0000",
      "cleaned_name": "political organization",
      "tag_name_unanalyzed_downcase": "political organization",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 10726,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.31651548,
      "_explanation": null
    },
    {
      "id": "5567e8bb7369641a658f0000",
      "cleaned_name": "package/freight delivery",
      "tag_name_unanalyzed_downcase": "package/freight delivery",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 10003,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.30109513,
      "_explanation": null
    },
    {
      "id": "5567e3ca736964371b130000",
      "cleaned_name": "wireless",
      "tag_name_unanalyzed_downcase": "wireless",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 9966,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.30029106,
      "_explanation": null
    },
    {
      "id": "5567e3657369642f4ec90000",
      "cleaned_name": "international affairs",
      "tag_name_unanalyzed_downcase": "international affairs",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 9942,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.29976872,
      "_explanation": null
    },
    {
      "id": "5567e28a7369642ae2500000",
      "cleaned_name": "public policy",
      "tag_name_unanalyzed_downcase": "public policy",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 9652,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.29340675,
      "_explanation": null
    },
    {
      "id": "556808697369647bfd420000",
      "cleaned_name": "libraries",
      "tag_name_unanalyzed_downcase": "libraries",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 8306,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.26259345,
      "_explanation": null
    },
    {
      "id": "5567e0cf7369641233e50600",
      "cleaned_name": "gambling & casinos",
      "tag_name_unanalyzed_downcase": "gambling & casinos",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 7816,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.2508102,
      "_explanation": null
    },
    {
      "id": "5567e14673696416d38c0300",
      "cleaned_name": "railroad manufacture",
      "tag_name_unanalyzed_downcase": "railroad manufacture",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 6500,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.21748394,
      "_explanation": null
    },
    {
      "id": "5567fd5a73696442b0f20000",
      "cleaned_name": "ranching",
      "tag_name_unanalyzed_downcase": "ranching",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 6361,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.21380983,
      "_explanation": null
    },
    {
      "id": "5567e2c572616932bb3b0000",
      "cleaned_name": "military",
      "tag_name_unanalyzed_downcase": "military",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 6219,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.21002407,
      "_explanation": null
    },
    {
      "id": "5567f96c7369642a22080000",
      "cleaned_name": "fishery",
      "tag_name_unanalyzed_downcase": "fishery",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 6008,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.20433708,
      "_explanation": null
    },
    {
      "id": "5567e2a97369642a553d0000",
      "cleaned_name": "supermarkets",
      "tag_name_unanalyzed_downcase": "supermarkets",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 5680,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.19534606,
      "_explanation": null
    },
    {
      "id": "5567e8a27369646ddb0b0000",
      "cleaned_name": "dairy",
      "tag_name_unanalyzed_downcase": "dairy",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 5677,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.19526295,
      "_explanation": null
    },
    {
      "id": "55680085736964551e070000",
      "cleaned_name": "tobacco",
      "tag_name_unanalyzed_downcase": "tobacco",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 5608,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.19334725,
      "_explanation": null
    },
    {
      "id": "5568047d7369646d406c0000",
      "cleaned_name": "shipbuilding",
      "tag_name_unanalyzed_downcase": "shipbuilding",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 5408,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.18774627,
      "_explanation": null
    },
    {
      "id": "55680a8273696407b61f0000",
      "cleaned_name": "judiciary",
      "tag_name_unanalyzed_downcase": "judiciary",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 5233,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.18278544,
      "_explanation": null
    },
    {
      "id": "5567e1a87369641f6d550100",
      "cleaned_name": "alternative dispute resolution",
      "tag_name_unanalyzed_downcase": "alternative dispute resolution",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 4170,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.15136984,
      "_explanation": null
    },
    {
      "id": "5567e7be736964110e210000",
      "cleaned_name": "nanotechnology",
      "tag_name_unanalyzed_downcase": "nanotechnology",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 3565,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.1324198,
      "_explanation": null
    },
    {
      "id": "55718f947369642142b84a12",
      "cleaned_name": "agriculture",
      "tag_name_unanalyzed_downcase": "agriculture",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 1425,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.057856206,
      "_explanation": null
    },
    {
      "id": "5567e1797369641c48c10100",
      "cleaned_name": "legislative office",
      "tag_name_unanalyzed_downcase": "legislative office",
      "parent_tag_id": null,
      "uid": null,
      "kind": "linkedin_industry",
      "has_children": false,
      "category": null,
      "tag_category_downcase": null,
      "num_organizations": 762,
      "num_people": 0,
      "_index_type": "tag",
      "_score": 0.031892985,
      "_explanation": null
    }
  ]