// @ts-nocheck
// Settings.jsx

import axios from "axios";
import React, { useState } from "react";
import styled from "styled-components";

const SettingsCard = styled.div`
  padding: 24px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
`;

const FormGroup = styled.div`
  margin-bottom: 24px;
`;

const FormLabel = styled.label`
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const SettingsInput = styled.input`
  width: 100%;
  height: 36px;
  padding: 8px;
  border: 1px solid #d1d5db;
  border-radius: 4px;

  &:focus {
    outline: none;
    border-color: #3b82f6;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  padding: 8px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: #3b82f6;
  }
`;

const Checkbox = styled(SettingsInput)`
  width: auto;
  height: auto;
  margin-right: 8px;
`;

const SaveButton = styled.button`
  background-color: #3b82f6;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #2563eb;
  }

  &:disabled {
    background-color: #93c5fd;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: #ef4444;
  font-size: 14px;
  margin-top: 4px;
`;

const SuccessMessage = styled.div`
  color: #10b981;
  font-size: 14px;
  margin-top: 4px;
`;

const Settings = ({ campaignId, campaignName, reloadCampaigns }) => {
  const [settings, setSettings] = useState({
    name: campaignName,
    track_settings: ["DONT_TRACK_EMAIL_OPEN"],
    stop_lead_settings: "REPLY_TO_AN_EMAIL",
    unsubscribe_text: "",
    send_as_plain_text: false,
    follow_up_percentage: 100,
    enable_ai_esp_matching: true,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSave = async () => {
    setIsLoading(true);
    setError("");
    setSuccess("");

    try {
      const user = JSON.parse(localStorage.getItem("actio_user"));
      const authToken = `Bearer ${user.id}@@@${user.email}`;

      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/campaign/${campaignId}/settings`,
        { settings },
        {
          headers: {
            Authorization: authToken,
            "Content-Type": "application/json",
          },
        }
      );

      setSuccess("Settings updated successfully!");
      await reloadCampaigns();
    } catch (error) {
      console.error("Error updating settings:", error);
      setError(error.message || "An error occurred while updating settings");
    } finally {
      setIsLoading(false);
    }
  };

  const handleStopLeadSettingsChange = (setting) => {
    setSettings((prev) => ({
      ...prev,
      stop_lead_settings: setting,
    }));
  };

  return (
    <SettingsCard>
      <FormGroup>
        <FormLabel>Campaign Name</FormLabel>
        <SettingsInput
          type="text"
          value={settings.name}
          onChange={(e) =>
            setSettings((prev) => ({ ...prev, name: e.target.value }))
          }
        />
      </FormGroup>

      <FormGroup>
        <FormLabel>Track Email Opens</FormLabel>
        <Checkbox
          type="checkbox"
          checked={!settings.track_settings.includes("DONT_TRACK_EMAIL_OPEN")}
          onChange={(e) =>
            setSettings((prev) => ({
              ...prev,
              track_settings: e.target.checked ? [] : ["DONT_TRACK_EMAIL_OPEN"],
            }))
          }
        />
      </FormGroup>

      <FormGroup>
        <FormLabel>Stop Lead Conditions</FormLabel>
        <div>
          <Checkbox
            type="checkbox"
            checked={settings.stop_lead_settings === "REPLY_TO_AN_EMAIL"}
            onChange={() => handleStopLeadSettingsChange("REPLY_TO_AN_EMAIL")}
          />
          <span>Stop on Reply</span>
        </div>
        <div>
          <Checkbox
            type="checkbox"
            checked={settings.stop_lead_settings === "CLICK_ON_A_LINK"}
            onChange={() => handleStopLeadSettingsChange("CLICK_ON_A_LINK")}
          />
          <span>Stop on Link Click</span>
        </div>
        <div>
          <Checkbox
            type="checkbox"
            checked={settings.stop_lead_settings === "OPEN_AN_EMAIL"}
            onChange={() => handleStopLeadSettingsChange("OPEN_AN_EMAIL")}
          />
          <span>Stop on Email Open</span>
        </div>
      </FormGroup>

      <FormGroup>
        <FormLabel>Unsubscribe Text</FormLabel>
        <TextArea
          value={settings.unsubscribe_text}
          onChange={(e) =>
            setSettings((prev) => ({
              ...prev,
              unsubscribe_text: e.target.value,
            }))
          }
          placeholder="Custom unsubscribe message (optional)"
        />
      </FormGroup>

      <FormGroup>
        <FormLabel>Email Format</FormLabel>
        <div>
          <Checkbox
            type="checkbox"
            checked={settings.send_as_plain_text}
            onChange={(e) =>
              setSettings((prev) => ({
                ...prev,
                send_as_plain_text: e.target.checked,
              }))
            }
          />
          <span>Send as Plain Text</span>
        </div>
      </FormGroup>

      <FormGroup>
        <FormLabel>Follow-up Percentage</FormLabel>
        <SettingsInput
          type="number"
          min="0"
          max="100"
          value={settings.follow_up_percentage}
          onChange={(e) =>
            setSettings((prev) => ({
              ...prev,
              follow_up_percentage: parseInt(e.target.value) || 0,
            }))
          }
        />
      </FormGroup>

      <FormGroup>
        <FormLabel>AI ESP Matching</FormLabel>
        <div>
          <Checkbox
            type="checkbox"
            checked={settings.enable_ai_esp_matching}
            onChange={(e) =>
              setSettings((prev) => ({
                ...prev,
                enable_ai_esp_matching: e.target.checked,
              }))
            }
          />
          <span>Enable AI ESP Matching</span>
        </div>
      </FormGroup>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 20,
          flexDirection: "column",
        }}
      >
        <SaveButton onClick={handleSave} disabled={isLoading}>
          {isLoading ? "Saving..." : "Save Settings"}
        </SaveButton>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {success && <SuccessMessage>{success}</SuccessMessage>}
      </div>
    </SettingsCard>
  );
};

export default Settings;
